import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Measure } from '../../../../models/measure';
import {
  selectCurrentMeasure
} from '../../../../features/measure/measure.selectors';
import {
  deleteMeasure,
  selectMeasure, updateMeasure
} from '../../../../features/measure/measure.actions';
import {
  selectMarker
} from '../../../../features/marker/marker.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import { selectVolume } from '../../../../features/volume/volume.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'measure-details',
  templateUrl: './measure-details.component.html',
  styleUrls: ['../details.component.scss'],
})
export class MeasureDetailsComponents {

  measure: Measure | null | undefined;
  @ViewChild('comment') textareaRef: ElementRef<HTMLTextAreaElement> | undefined;
  private deleteDialogRef: MatDialogRef<DeleteConfirmDialog> | undefined;
  private renameDialogRef: MatDialogRef<RenameDialog> | undefined;

  constructor(private store: Store, private dialog: MatDialog, private translocoService: TranslocoService) {
    this.store.select(selectCurrentMeasure)
      .pipe(takeUntilDestroyed())
      .subscribe((measure: Measure | null | undefined) => {
        this.measure = measure;
        if (this.measure) {
          this.store.dispatch(selectMarker({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));
        }
      });
  }

  private save(): void {
    if (this.measure && this.textareaRef){
      this.store.dispatch(updateMeasure({update: {id: this.measure.id, changes: {comment: this.textareaRef.nativeElement.value}}}));
    }
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.save();
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickFlyTo(measure: Measure): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(measure.center, measure.cameraDirection, measure.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }


  onClickClose(): void {
    this.save();
    this.store.dispatch(selectMeasure({id: undefined}));
  }

  onClickDelete(measure: Measure): void {
    this.deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    this.deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: measure.name});
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deleteMeasure({id: measure.id}));
      }
    });
  }

  onClickExportData(measure: Measure): void {
    ExportTools.exportJSON(measure.name, JSON.stringify(measure));
  }

  onClickRename(measure: Measure): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    this.renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    this.renameDialogRef.componentInstance.placeholder = measure.name;
    this.renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.measure) {
        this.store.dispatch(updateMeasure({ update: {id: measure.id, changes: {name: result}}}));
      }
    });
  }
}
