import { Action, createReducer, on } from '@ngrx/store';
import { ConfigSideMode } from '../../enums/config';
import * as ConfigActions from './config.actions';
import { setSiteDetailSelectedSection } from './config.actions';

export interface State {
  sideMode: ConfigSideMode;
  displaySitesFilters: boolean,
  sitesFilters: any,
  colorRampPositiveColors: Array<string>;
  colorRampNegativeColors: Array<string> ;
  colorRampMin: number;
  colorRampMax: number;
  siteDetailSelectedSection: number | undefined;
  searchWorldText: string | undefined;
}

export const initialState: State = {
  sideMode: ConfigSideMode.MODE_EXPANDED,
  displaySitesFilters: false,
  sitesFilters: undefined,
  colorRampPositiveColors: [],
  colorRampNegativeColors: [],
  colorRampMin: 0,
  colorRampMax: 0,
  siteDetailSelectedSection: undefined,
  searchWorldText: undefined,
};


const configReducer = createReducer(
  initialState,
  on(ConfigActions.setConfigSideMode, (state, { sideMode }) => {
    return { ...state, sideMode: sideMode };
  }),
  on(ConfigActions.setDisplaySitesFilters, (state, { display }) => {
    return { ...state, displaySitesFilters: display };
  }),
  on(ConfigActions.setSitesFilters, (state, { sitesFilters }) => {
    return { ...state, sitesFilters: sitesFilters };
  }),
  on(ConfigActions.setColorRampPositiveColors, (state, { colors }) => {
    return { ...state, colorRampPositiveColors: colors };
  }),
  on(ConfigActions.setColorRampNegativeColors, (state, { colors }) => {
    return { ...state, colorRampNegativeColors: colors };
  }),
  on(ConfigActions.setColorRampMin, (state, { value }) => {
    return { ...state, colorRampMin: value };
  }),
  on(ConfigActions.setColorRampMax, (state, { value }) => {
    return { ...state, colorRampMax: value };
  }),
  on(ConfigActions.setSiteDetailSelectedSection, (state, { value }) => {
    return { ...state, siteDetailSelectedSection: value };
  }),
  on(ConfigActions.setSearchWorldText, (state, { text }) => {
    return { ...state, searchWorldText: text };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return configReducer(state, action);
}
