import { autoserialize, autoserializeAs } from 'cerialize';
import { environment } from '../../environments/environment';

export class Batch {
  @autoserialize id!: string;
  @autoserialize pin!: any;
  @autoserialize altitude: number = 0;
  @autoserialize outline!: [];
  @autoserialize tilesetUrl: string = environment.tilesUrl + '/40/tileset.json';
  @autoserializeAs('date_acquired') date_acquired!: number;
  @autoserializeAs('date_created') dateCreated!: number;
}
