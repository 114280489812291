export enum VolumeColorMode {
  COLOR_MODE_EMERGENCY = 1,
  COLOR_MODE_VOLUME_MOVED = 2,
}

export enum VolumeBorder {
  DEFAULT = '0.1px solid rgba(0, 0, 0, 0.1)',
}

export enum VolumeBackgroundColor {
  DEFAULT = '#FFFFFF',
}

