import {
  autoserialize,
  serialize,
  serializeAs
} from 'cerialize';
import { Measure } from './measure';
import { Cut } from './cut';
import { Marker } from './marker';
import { Volume } from './volume';
import { Color } from '../enums/color';
import { Detection } from './detection';
import { ItemNamePipe } from '../shared/pipes/tools.pipe';


export class InspectionReportVolume {
  @serialize index!: number;
  @serialize color!: string;
  @serializeAs('emergency_color') emergencyColor!: string;

  static fromVolume(volume: Volume | undefined): InspectionReportVolume {
    let obj = new InspectionReportVolume()
    if (volume) {
      obj.index = volume.index;
      obj.color = volume.hexColor;
      obj.emergencyColor = volume.textColor;
    }
    return obj
  }

  static fromVolumes(volumes: Array<Volume> | undefined): Array<InspectionReportVolume> {
    return (volumes) ? volumes?.map((v: Volume) => InspectionReportVolume.fromVolume(v)) : [];
  }
}

export class InspectionReportDetection {
  @serialize name!: string;
  @serialize label!: string;
  @serialize resource!: string;
  @serialize status!: number;
  @serialize confidence!: number;
  @serialize evolution!: number;
  @serialize color!: string;
  @serializeAs("emergency_level") emergencyLevel!: number | undefined;
  @serializeAs("bounding_box") boundingBox!: Array<number> | undefined;
  @serialize center!: Array<number>;


  static fromDetection(detection: Detection | undefined): InspectionReportDetection {
    let obj = new InspectionReportDetection();
    if (detection) {
      obj.name = detection.name;
      obj.label = detection.label;
      obj.resource = detection.resource;
      obj.status = detection.status;
      obj.confidence = detection.confidence;
      obj.evolution = detection.evolution;
      obj.color = detection.textColor;
      obj.emergencyLevel = (detection.emergency) ? detection.emergency.level : undefined;
      obj.boundingBox = (detection.boundingBox) ? detection.boundingBox : undefined;
      obj.center = detection.center;
    }
    return obj
  }

  static fromDetections(detections: Array<Detection> | undefined): Array<InspectionReportDetection> {
    return (detections) ? detections?.map((d: Detection) => InspectionReportDetection.fromDetection(d)) : [];
  }
}

export class InspectionReportSettings {
  @serializeAs('formats') formats: string[] | undefined;
  @serializeAs('emergency_colors') emergencyColors: string[] | undefined;
  @serializeAs('color_ramp_positive_colors') colorRampPositiveColors: string[] | undefined;
  @serializeAs('color_ramp_negative_colors') colorRampNegativeColors: string[] | undefined;
  @serializeAs('color_ramp_min') colorRampMin: number | undefined;
  @serializeAs('color_ramp_max') colorRampMax: number | undefined;

  static with(formats: string[] | undefined,
              positiveColors: string[] | undefined,
              negativeColors: string[] | undefined,
              min: number | undefined,
              max: number | undefined,
              emergencyColors: string[] | undefined): InspectionReportSettings {
    let obj = new InspectionReportSettings()
    obj.formats = formats;
    obj.colorRampPositiveColors = positiveColors;
    obj.colorRampNegativeColors = negativeColors;
    obj.colorRampMin = min;
    obj.colorRampMax = max;
    obj.emergencyColors = emergencyColors;
    return obj
  }
}

export class InspectionReport {
  @serializeAs(InspectionReportSettings) settings!: InspectionReportSettings;
  @serializeAs(Measure) measures!: Array<Measure>;
  @serializeAs(Cut) cuts!: Array<Cut>;
  @serializeAs(Marker) markers!: Array<Marker>;
  @serializeAs(InspectionReportVolume) volumes!: Array<InspectionReportVolume>;
  @serializeAs(InspectionReportDetection) detections!: Array<InspectionReportDetection>;
}
