<div *ngIf="site" class="container">
  <div class="actions">
    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 0}"
         (click)="selectList(0)"
         *ngIf="site | module: [inspectionModule.ARCAD]">
      <mat-icon matTooltip="{{'detections' | transloco}}">
        stacks
        <div class="badge"
             *ngIf="(totalDetection | async) as totalDetection"
             [ngClass]="{'badge-active': currentListSelected == 0}"
             [hidden]="totalDetection==0">{{totalDetection}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 1}"
         (click)="selectList(1)"
         *ngIf="site.previousBatch && (site | module: [inspectionModule.PERREAD])">
      <mat-icon matTooltip="{{'volumes' | transloco}}">
        deployed_code
        <div class="badge"
             *ngIf="(totalVolume | async) as totalVolume"
             [ngClass]="{'badge-active': currentListSelected == 1}"
             [hidden]="totalVolume==0">{{totalVolume}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 2}"
         (click)="selectList(2)">
      <mat-icon matTooltip="{{'measures' | transloco}}">
        design_services
        <div class="badge"
             *ngIf="(totalMeasure | async) as totalMeasure"
             [ngClass]="{'badge-active': currentListSelected == 2}"
             [hidden]="totalMeasure==0">{{totalMeasure}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 3}"
         (click)="selectList(3)">
      <mat-icon matTooltip="{{'markers' | transloco}}">
        radio_button_checked
        <div class="badge"
             *ngIf="(totalMarker | async) as totalMarker"
             [ngClass]="{'badge-active': currentListSelected == 3}"
             [hidden]="totalMarker==0">{{totalMarker}}</div>
      </mat-icon>
    </div>

    <div class="item"
         [ngClass]="{'item-selected': currentListSelected == 4}"
         (click)="selectList(4)">
      <mat-icon matTooltip="{{'cuts' | transloco}}">
        landscape
        <div class="badge"
             *ngIf="(totalCut | async) as totalCut"
             [ngClass]="{'badge-active': currentListSelected == 4}"
             [hidden]="totalCut==0">{{totalCut}}</div>
      </mat-icon>
    </div>
  </div>


  <div class="lists">
    <detection-list class="list"
                    [ngClass]="{'list-selected': currentListSelected == 0}">
    </detection-list>

    <volume-list class="list"
                 [ngClass]="{'list-selected': currentListSelected == 1}">
    </volume-list>

    <measure-list class="list"
                  [ngClass]="{'list-selected': currentListSelected == 2}">
    </measure-list>

    <marker-list class="list"
                 [ngClass]="{'list-selected': currentListSelected == 3}">
    </marker-list>

    <cut-list class="list"
              [ngClass]="{'list-selected': currentListSelected == 4}">
    </cut-list>
  </div>


</div>
