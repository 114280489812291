<mat-card class="container" *ngIf="volume">

  <div [style.background-color]="volume.hexColor"
       class="volume-color-box"></div>

  <mat-card-header style="padding-bottom: 16px; padding-left: 42px !important;">
    <mat-card-title-group>
      <mat-card-title>
        <span style="position: relative; font-weight: 600">
          {{'volume' | itemName: volume.id}}
          <div *ngIf="volume.emergency" class="emergency-badge" [style.background-color]="volume.textColor"></div>
        </span>
      </mat-card-title>
      <mat-card-subtitle>{{volume.value | number:'1.2-2'}} m3</mat-card-subtitle>
      <mat-card-subtitle>{{this.volume.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>

    <div class="selector-content">
      <form [formGroup]="form">
        <mat-form-field *ngIf="emergencyTemplate">
          <mat-label>{{'emergency_level' | transloco}}</mat-label>
          <mat-select formControlName="emergency">
            <mat-option *ngFor="let item of emergencyTemplate.labels;" value="{{item.priority}}">
              <span style="position: relative;">
                {{item.label}}
                <div class="emergency-badge" [style.background-color]="emergencyLevelColor(item.level)"></div>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <history-channel [detectionId]="volume.id"></history-channel>

  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div>
<!--      <button mat-icon-button-->
<!--              matTooltip="{{'export' | transloco}}"-->
<!--              class="material-symbols-outlined"-->
<!--              [matMenuTriggerFor]="menu">ios_share-->
<!--      </button>-->
<!--      <mat-menu #menu="matMenu">-->
<!--&lt;!&ndash;        <button mat-menu-item (click)="onClickExportDXF(volume)">{{'export_dxf' | transloco}}</button>&ndash;&gt;-->
<!--        <button mat-menu-item (click)="onClickExportData(volume)">{{'export_data' | transloco}}</button>-->
<!--      </mat-menu>-->
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_volume' | transloco}}"
              (click)="onClickFlyTo(volume)">location_on
      </button>
    </div>
    <button mat-icon-button
            [ngClass]="{'btn-save-enable': formHasChanged, 'btn-save-disable': !formHasChanged}"
            [disabled]="!formHasChanged"
            matTooltip="{{'save_changes' | transloco}}"
            (click)="onClickUpdate(volume)">done
    </button>
  </mat-card-actions>
</mat-card>
