import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'delete-confirm-dialog',
  templateUrl: './delete-confirm.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class DeleteConfirmDialog {
  constructor(public dialogRef: MatDialogRef<DeleteConfirmDialog>) {}

  public message:string = '';
}
