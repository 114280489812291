import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Serialize } from 'cerialize';
import { ApiService } from '../../services/api.service';
import * as ReportActions from './report.actions';


@Injectable()
export class ReportEffects {

  generateReport$ = createEffect(() => {
    return this.actions$.pipe(ofType(ReportActions.generateReport),
      concatMap(({organizationId, siteId, batchId, payload}) =>
      this.api.generateReport$(organizationId, siteId, batchId, Serialize(payload)).pipe(
        map(email => ReportActions.generateReportSuccess({ email })),
        catchError(error => of(ReportActions.generateReportFailure({ error }))))
    ));
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
