import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  selectAllOrganizations, selectCurrentOrganization
} from '../../../features/organization/organization.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Organization } from '../../../models/organization';


@Component({
  selector: 'organization-dialog',
  templateUrl: './organization.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class OrganizationDialog {

  organizationFormControl = new FormControl();
  organizations: Array<Organization> | undefined | null;

  constructor(public dialogRef: MatDialogRef<OrganizationDialog>, private store: Store) {
    this.initHandleOrganizations();
  }

  private initHandleOrganizations(): void {
    this.store.select(selectAllOrganizations)
      .pipe(takeUntilDestroyed())
      .subscribe((organizations: Array<Organization> | undefined | null) => {
        this.organizations = organizations;
      });

    this.store.select(selectCurrentOrganization)
      .pipe(takeUntilDestroyed())
      .subscribe((organization: Organization | undefined | null) => {
        this.organizationFormControl.setValue(organization?.id);
      });
  }

}
