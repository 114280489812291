import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';
import { Observable } from 'rxjs';
import {
  selectDetectionTotal
} from '../../../features/detection/detection.selectors';
import { selectVolumeTotal } from '../../../features/volume/volume.selectors';
import {
  selectMeasureTotal
} from '../../../features/measure/measure.selectors';
import { selectMarkerTotal } from '../../../features/marker/marker.selectors';
import { selectCutTotal } from '../../../features/cut/cut.selectors';
import {
  selectSiteDetailSelectedSection
} from '../../../features/config/config.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  setSiteDetailSelectedSection
} from '../../../features/config/config.actions';
import { InspectionModulePipe } from '../../../shared/pipes/module.pipe';

@Component({
  selector: 'site-details-content',
  templateUrl: './site-details-content.component.html',
  styleUrls: ['./site-details-content.component.scss']
})
export class SiteDetailsContentComponent {

  inspectionModule = InspectionModule;
  currentListSelected: number | undefined = 0;
  site: Site | null | undefined;

  constructor(private store: Store, private modulePipe: InspectionModulePipe) {
    this.store.select(selectSiteDetailSelectedSection)
      .pipe(takeUntilDestroyed())
      .subscribe(value => {
        this.currentListSelected = value;
      });
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe(site => {
        this.site = site;
        if (this.site) {
          const isDetectionAvailable = this.modulePipe.transform(this.site, [InspectionModule.ARCAD]);
          const isVolumeAvailable = this.modulePipe.transform(this.site, [InspectionModule.PERREAD]);
          if (isDetectionAvailable){
            this.store.dispatch(setSiteDetailSelectedSection({value: 0}));
          }
          else if (isVolumeAvailable){
            this.store.dispatch(setSiteDetailSelectedSection({value: 1}));
          }
          else {
            this.store.dispatch(setSiteDetailSelectedSection({value: 2}));
          }
        }
      });
  }

  get totalDetection(): Observable<number> {
    return this.store.select(selectDetectionTotal);
  }

  get totalVolume(): Observable<number> {
    return this.store.select(selectVolumeTotal);
  }

  get totalMeasure(): Observable<number> {
    return this.store.select(selectMeasureTotal);
  }

  get totalMarker(): Observable<number> {
    return this.store.select(selectMarkerTotal);
  }

  get totalCut(): Observable<number> {
    return this.store.select(selectCutTotal);
  }

  selectList(index: number): void {
    this.store.dispatch(setSiteDetailSelectedSection({value: index}));
  }
}
