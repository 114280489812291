import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CutActions from './cut.actions';
import { Cut } from '../../models/cut';


export interface State extends EntityState<Cut> {
  selectedId: string | undefined;
}

export const adapter: EntityAdapter<Cut> = createEntityAdapter<Cut>();

export const initialState: State = adapter.getInitialState({selectedId: undefined});

const cutReducer = createReducer(
  initialState,
  on(CutActions.addCut, (state) => {
    return state;
  }),
  on(CutActions.addCutSuccess, (state, { cut }) => {
    return adapter.setOne(cut, state);
  }),
  on(CutActions.addCutFailure, (state, { error }) => {
    return state;
  }),
  on(CutActions.updateCut, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(CutActions.deleteCut, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(CutActions.clearAllCuts, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(CutActions.selectCut, (state, {id}) => {
    return { ...state, selectedId: id };
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return cutReducer(state, action);
}

export const getSelectedCutId = (state: State) => state.selectedId;
