import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as VolumeActions from './volume.actions';
import { Deserialize } from 'cerialize';
import { Volume } from '../../models/volume';
import { ApiService } from '../../services/api.service';
import { ColorTools } from '../../tools/color.tools';
import { EmergencyTemplate } from '../../models/emergency-template';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@jsverse/transloco';


@Injectable()
export class VolumeEffects {

  loadVolumes$ = createEffect(() => {
    return this.actions$.pipe(ofType(VolumeActions.loadVolumes),
      concatMap(({organizationId, siteId, batchId, threshold, step}) =>
      this.api.volumes$(organizationId, siteId, batchId, threshold).pipe(
        map(volumes => volumes.map((volume: any) => {
          const obj: Volume = Deserialize(volume, Volume);
          obj.name = this.translocoService.translate('volume') + " " + obj.id.slice(0,4).toUpperCase();
          return obj;
        })),
        map(volumes => ColorTools.setColorsForVolumes(volumes, step, this.store)),
        map(volumes => VolumeActions.loadVolumesSuccess({ volumes })),
        catchError(error => of(VolumeActions.loadVolumesFailure({ error }))))
    ));
  });

  loadVolumeEmergencyTemplate$ = createEffect(() => {
    return this.actions$.pipe(ofType(VolumeActions.loadVolumeEmergencyTemplate),
      concatMap(({organizationId, siteId}) =>
        this.api.volumeEmergencyTemplate$(organizationId, siteId).pipe(
          map(template => Deserialize(template, EmergencyTemplate)),
          map(template => VolumeActions.loadVolumeEmergencyTemplateSuccess({ template })),
          catchError(error => of(VolumeActions.loadVolumeEmergencyTemplateFailure({ error }))))
      ));
  });

  addVolumeEmergency$ = createEffect(() => {
    return this.actions$.pipe(ofType(VolumeActions.addVolumeEmergency),
      concatMap(({organizationId, siteId, batchId, volumeId, payload}) =>
        this.api.addVolumeEmergency$(organizationId, siteId, batchId, volumeId, payload).pipe(
          map(volume => ColorTools.setEmergencyColors(Deserialize(volume, Volume))),
          map(volume => {
            return {id: volume.id, changes: volume};
          }),
          map(update => VolumeActions.addVolumeEmergencySuccess({ update })),
          catchError(error => of(VolumeActions.addVolumeEmergencyFailure({ error }))))
      ));
  });

  constructor(private store: Store,
              private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
