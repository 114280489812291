import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'user-configuration-error-dialog',
  templateUrl: './user-configuration-error.dialog.html',
  styleUrls: ['./user-configuration-error.dialog.scss']
})
export class UserConfigurationErrorDialog {
  constructor(public dialogRef: MatDialogRef<UserConfigurationErrorDialog>) {}

}
