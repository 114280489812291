<mat-card *ngIf="marker"
          style="cursor: pointer;"
          [style.border]=border
          (click)="onClickEdit(marker)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{marker.name}}</mat-card-title>
  </mat-card-header>
</mat-card>



