import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Marker, MarkerImage } from '../../../../models/marker';
import {
  selectCurrentMarker
} from '../../../../features/marker/marker.selectors';
import {
  deleteMarker,
  selectMarker, updateMarker
} from '../../../../features/marker/marker.actions';
import { selectVolume } from '../../../../features/volume/volume.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import {
  selectMeasure,
} from '../../../../features/measure/measure.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import {
  PicturesViewerDialog
} from '../../../dialogs/pictures-viewer-dialog/pictures-viewer.dialog';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { first } from 'rxjs';
import { Site } from '../../../../models/site';
import { ApiService } from '../../../../services/api.service';
import { MarkerSortDistancePipe } from '../../../pipes/marker.pipe';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'marker-details',
  templateUrl: './marker-details.component.html',
  styleUrls: ['../details.component.scss'],
})
export class MarkerDetailsComponents {

  marker: Marker | null | undefined;
  @ViewChild('comment') textareaRef: ElementRef<HTMLTextAreaElement> | undefined;

  constructor(private store: Store,
              private api: ApiService,
              private sortDistancePipe: MarkerSortDistancePipe,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.store.select(selectCurrentMarker)
      .pipe(takeUntilDestroyed())
      .subscribe((marker: Marker | null | undefined) => {
        this.marker = marker;
        if (this.marker) {
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));
        }
      });
  }

  private save(): void {
    if (this.marker && this.textareaRef){
      this.store.dispatch(updateMarker({update: {id: this.marker.id, changes: {comment: this.textareaRef.nativeElement.value}}}));
    }
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.save();
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickFlyTo(marker: Marker): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(marker.center, marker.cameraDirection, marker.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.save();
    this.store.dispatch(selectMarker({id: undefined}));
  }

  onClickExportPicture(marker: Marker): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site && marker?.markerBatchImages) {
          const lastMarkerBatchImages = marker.markerBatchImages.filter(mbi => mbi.batch == site.lastBatch)[0];
          const data = this.sortDistancePipe.transform(lastMarkerBatchImages.data);
          if (data.length) {
            const firstMarkerImage: MarkerImage = data[0];
            this.api.imageTmpUrl$(site.organization, site.id, site.lastBatch, firstMarkerImage.name)
              .pipe(first()).subscribe(url => {
              const filename = marker.name.replace(/ /g,"_");
              ExportTools.exportResourceUrl(filename, url);
            });
          }
        }
      });
  }

  onClickExportData(marker: Marker): void {
    ExportTools.exportJSON(marker.name, JSON.stringify(marker));
  }

  onClickDisplayPictures(marker: Marker): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site && marker?.markerBatchImages) {
          const lastMarkerBatchImages = marker.markerBatchImages.filter(mbi => mbi.batch == site.lastBatch)[0];
          const data = this.sortDistancePipe.transform(lastMarkerBatchImages.data);
          if (data.length) {
            const markerImages: Array<MarkerImage> = data.slice(0,5);
            const images = markerImages.map(mi => mi.name);
            this.api.imagesTmpUrls$(site.organization, site.id, site.lastBatch, images)
              .pipe(first())
              .subscribe(results => {
                console.log(results);
                const pictureViewerDialogRef = this.dialog.open(PicturesViewerDialog,{
                  disableClose: true
                });
                pictureViewerDialogRef.componentInstance.title = marker.name
                pictureViewerDialogRef.componentInstance.subtitle = marker.center[0].toFixed(10) +", "+ marker.center[1].toFixed(10);
                pictureViewerDialogRef.componentInstance.images = results;
            });
          }
        }
      });
  }

  onClickDelete(marker: Marker): void {
    const deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: marker.name});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deleteMarker({id: marker.id}));
      }
    });
  }

  onClickRename(marker: Marker): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    const renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    renameDialogRef.componentInstance.placeholder = marker.name;
    renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.marker) {
        this.store.dispatch(updateMarker({ update: {id: marker.id, changes: {name: result}}}));
      }
    });
  }
}
