import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { parse } from 'yaml';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: HttpClient, public router: Router) {}

  handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error);
    // Return an observable with a user-facing error message.
    return throwError(() =>'Something bad happened; please try again later.');
  }

  loadAsset$(url: string, headers?: HttpHeaders): Observable<any> {
    return this.http.get(url, {
      observe: 'body',
      responseType: 'text',
      headers,
    }).pipe(
      catchError(this.handleError),
      map(data => parse(data))
    );
  }

  realm$(): Observable<any> {
    return this.http.get(environment.apiUrl + '/realm/')
      .pipe(catchError(this.handleError),
      );
  }

  me$(): Observable<any> {
    return this.http.get(environment.apiUrl + '/me/').pipe(
      catchError(this.handleError)
    );
  }

  organizations$(): Observable<any> {
    return this.http.get(environment.apiUrl + '/organizations/')
      .pipe(catchError(this.handleError),
      );
  }

  sites$(organizationId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/organizations/' + organizationId + '/sites/')
      .pipe(catchError(this.handleError),
      );
  }

  site$(organizationId: string, siteId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/organizations/' + organizationId + '/sites/' + siteId + '/')
      .pipe(catchError(this.handleError),
      );
  }

  sectors$(organizationId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/organizations/' + organizationId + '/sectors/')
      .pipe(catchError(this.handleError),
      );
  }

  batchs$(siteId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/sites/' + siteId + '/batchs/')
      .pipe(catchError(this.handleError),
      );
  }

  imageTmpUrl$(organizationId: string,
               siteId: string,
               batchId: string,
               imageName: string): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/image_tmp_url/?image_name='+imageName)
      .pipe(catchError(this.handleError),
      );
  }

  imagesTmpUrls$(organizationId: string,
                 siteId: string,
                 batchId: string,
                 images: Array<string>): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/images_tmp_urls/', {images: images})
      .pipe(catchError(this.handleError),
      );
  }

  detections$(organizationId: string,
              siteId: string,
              batchId: string): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/arcad/detections/')
      .pipe(catchError(this.handleError),
      );
  }

  detectionComments$(detectionId: string): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/detections/' + detectionId +
      '/comments/')
      .pipe(catchError(this.handleError),
      );
  }

  addDetectionComment$(detectionId: string, payload: any): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/detections/' + detectionId +
      '/comments/', payload)
      .pipe(catchError(this.handleError),
      );
  }

  addDetection$(organizationId: string,
                siteId: string,
                batchId: string,
                payload: any): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/arcad/detections/', payload)
      .pipe(catchError(this.handleError),
      );
  }

  updateDetection$(organizationId: string,
                   siteId: string,
                   batchId: string,
                   detectionId: string,
                   payload: any): Observable<any> {
    return this.http.put(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/arcad/detections/'+ detectionId +
      '/', payload)
      .pipe(catchError(this.handleError),
      );
  }

  addDetectionEmergency$(organizationId: string,
                         siteId: string,
                         batchId: string,
                         detectionId: string,
                         payload: any): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/arcad/detections/'+ detectionId +
      '/add_emergency/', payload)
      .pipe(catchError(this.handleError),
      );
  }

  generateReport$(organizationId: string,
                  siteId: string,
                  batchId: string,
                  payload: any): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/structure/report/', payload)
      .pipe(catchError(this.handleError),
      );
  }

  volumes$(organizationId: string,
           siteId: string,
           batchId: string,
           threshold: number): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/perread/detections/?threshold='+threshold)
      .pipe(catchError(this.handleError),
      );
  }

  addVolumeEmergency$(organizationId: string,
                      siteId: string,
                      batchId: string,
                      volumeId: string,
                      payload: any): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/batchs/' + batchId +
      '/perread/detections/'+ volumeId +
      '/add_emergency/', payload)
      .pipe(catchError(this.handleError),
      );
  }

  measureDistance$(organizationId: string,
                    siteId: string,
                    point1: Array<number>,
                    point2: Array<number>,
                    cameraPosition: Array<number>): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/measure_distance/', {
      point1,
      point2,
      camera_position: cameraPosition,
    })
      .pipe(catchError(this.handleError),
      );
  }

  measureArea$(organizationId: string,
                siteId: string,
                polygon: Array<Array<number>>,
                cameraPosition: Array<number>): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/measure_area/', {
      polygon,
      camera_position: cameraPosition
    })
      .pipe(catchError(this.handleError),
      );
  }

  marker$(organizationId: string,
          siteId: string,
          point: Array<number>,
          cameraPosition: Array<number>): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/marker/', {point, camera_position: cameraPosition})
      .pipe(catchError(this.handleError),
      );
  }

  cut$(organizationId: string,
       siteId: string,
       point1: Array<number>,
       point2: Array<number>): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/cut/', {point1, point2})
      .pipe(catchError(this.handleError),
      );
  }

  exportCutDXF$(organizationId: string,
                siteId: string,
                cuts: Array<any>,
                slope: Array<Array<number>>,
                language_code: string): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/export_cut_dxf/', {cuts, slope, language_code})
      .pipe(catchError(this.handleError),
      );
  }


  detectionTemplate$(organizationId: string,
                     siteId: string,): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/arcad/detection-template/')
      .pipe(catchError(this.handleError),
      );
  }


  detectionEmergencyTemplate$(organizationId: string,
                              siteId: string,): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/arcad/emergency-template/')
      .pipe(catchError(this.handleError),
      );
  }

  volumeEmergencyTemplate$(organizationId: string,
                           siteId: string,): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/perread/emergency-template/')
      .pipe(catchError(this.handleError),
      );
  }

  detectionsSummary$(organizationId: string,
                     siteId: string,): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/arcad/summary/')
      .pipe(catchError(this.handleError),
      );
  }

  volumesSummary$(organizationId: string,
                  siteId: string,): Observable<any> {
    return this.http.get(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/perread/summary/')
      .pipe(catchError(this.handleError),
      );
  }

  activateSite$(organizationId: string,
                siteId: string): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/activate/', {})
      .pipe(catchError(this.handleError),
      );
  }

  deactivateSite$(organizationId: string,
                  siteId: string): Observable<any> {
    return this.http.post(environment.apiUrl +
      '/organizations/' + organizationId +
      '/sites/' + siteId +
      '/structure/deactivate/', {})
      .pipe(catchError(this.handleError),
      );
  }
}
