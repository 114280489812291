import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromOrganization from './organization.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromOrganization.adapter.getSelectors();

export const selectOrganizationState = createFeatureSelector<fromOrganization.State>('organizations');

export const selectOrganizationIds = createSelector(
  selectOrganizationState,
  selectIds
);
export const selectOrganizationEntities = createSelector(
  selectOrganizationState,
  selectEntities
);
export const selectAllOrganizations = createSelector(
  selectOrganizationState,
  selectAll
);
export const selectOrganizationTotal = createSelector(
  selectOrganizationState,
  selectTotal
);
export const selectCurrentOrganizationId = createSelector(
  selectOrganizationState,
  fromOrganization.getSelectedOrganizationId
);

export const selectCurrentOrganization = createSelector(
  selectOrganizationEntities,
  selectCurrentOrganizationId,
  (organizationEntities, organizationId) => (organizationId) ? organizationEntities[organizationId] : null
);
