export enum SiteFilterKeys {
  SECTORS = 'sectors',
  EMERGENCIES = 'emergencies',
  DETECTIONS = 'detections',
  VOLUMES = 'volumes',
}

export enum SiteFilterDetectionKeys {
  HIGH_EMERGENCY_LEVEL = 'detectionHighEmergencyLevel',
  TOTAL = 'detectionTotal',
  EVOLUTION = 'detectionEvolution',
  DENSITY_PEAK = 'detectionDensityPeak',
}

export enum SiteFilterVolumeKeys {
  HIGH_EMERGENCY_LEVEL = 'volumeHighEmergencyLevel',
  POSITIVE_VOLUME_MOVED = 'volumePositiveVolumeMoved',
  NEGATIVE_VOLUME_MOVED = 'volumeNegativeVolumeMoved',
  DENSITY_PEAK = 'volumeDensityPeak',
}

