import { Injectable } from '@angular/core';
import { Site } from '../models/site';
import { CameraConfiguration } from '../features/cesium/cesium.reducer';
import {
  setCameraConfiguration,
  setCameraMode
} from '../features/cesium/cesium.actions';
import { CameraMode } from '../enums/camera';
import { Store } from '@ngrx/store';


@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private store: Store) {}

  flyTo(site: Site): void {
    const cartographic = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromArray([site.pin[0], site.pin[1], site.pin[2]]));
    const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    const latitude = Cesium.Math.toDegrees(cartographic.latitude);
    const height:number = site.height + site.altitude + 50;
    let position = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);
    const offset = new Cesium.Cartesian3(1000, 0, 0);
    position = Cesium.Cartesian3.add(position, offset, position);
    const orientation = {
      heading: Cesium.Math.toRadians(0),
      pitch: Cesium.Math.toRadians(-45),
      roll: Cesium.Math.toRadians(0),
    }
    const cameraConfiguration: CameraConfiguration = {position, orientation};
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  modulesSummaries(site: Site): void {

  }
}
