import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'rename-dialog',
  templateUrl: './rename.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class RenameDialog {
  constructor(public dialogRef: MatDialogRef<RenameDialog>) {}

  public message: string = '';
  public placeholder: string = '';
  public name: string | undefined;
}
