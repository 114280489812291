import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';
import { SiteService } from '../../../services/site.service';
import {
  activateSite,
  selectSite
} from '../../../features/site/site.actions';
import { SiteStatus } from '../../../enums/site';
import {
  SiteActivationDialog
} from '../../../shared/dialogs/site-activation-dialog/site-activation.dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'site-card',
  templateUrl: './site-card.component.html',
  styleUrls: ['./site-card.component.scss']
})
export class SiteCardComponent {

  @Input() site: Site | undefined
  inspectionModule = InspectionModule;

  constructor(private store: Store,
              private siteService: SiteService,
              private dialog: MatDialog,) {
  }

  onClickFlyToSite(site: Site) {
    this.siteService.flyTo(site);
  }

  onClickSelectSite(site: Site) {
    this.store.dispatch(selectSite({siteId: site.id}));
  }

  onClickActivateSite(site: Site) {
    const activateSiteDialogRef = this.dialog.open(SiteActivationDialog);
    activateSiteDialogRef.componentInstance.siteName = site.name;
    activateSiteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(activateSite({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    });
  }

  get siteAvailable(): boolean {
    if (this.site) {
      return this.site.status == SiteStatus.UPDATING || this.site.status == SiteStatus.RUNNING;
    }
    return false;
  }

  get siteInActivation(): boolean {
    if (this.site) {
      return this.site.status == SiteStatus.PENDING || this.site.status == SiteStatus.INITIALIZING;
    }
    return false;
  }
}
