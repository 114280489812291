<div class="container">
  <div class="module-title">{{'history' | transloco | uppercase}} :</div>

  <div class="comments" #commentList>
    <div class="comment-box" *ngFor="let comment of comments">
      <p>{{comment.text}}</p>
      <div class="comment-infos">
        <span *ngIf="comment.author">{{comment.author}}&nbsp;-&nbsp;</span>{{ dateToTimestamp(comment.dateCreated) | since }}
      </div>
    </div>
  </div>


  <div class="comment-editor">
      <textarea placeholder="{{'comment' | transloco}}"
                #editor
                (focus)="onHandleFocus()"
                (focusout)="onFocusLeave()"></textarea>
    <button mat-icon-button
            matTooltip="{{'add_comment' | transloco}}"
            [disabled]="!editor.value"
            (click)="onClickAddComment()"><mat-icon>send</mat-icon></button>
  </div>
</div>
