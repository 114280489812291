import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromSector from './sector.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromSector.adapter.getSelectors();

export const selectSectorState = createFeatureSelector<fromSector.State>('sectors');

export const selectSectorIds = createSelector(
  selectSectorState,
  selectIds
);
export const selectSectorEntities = createSelector(
  selectSectorState,
  selectEntities
);
export const selectAllSectors = createSelector(
  selectSectorState,
  selectAll
);
export const selectSectorTotal = createSelector(
  selectSectorState,
  selectTotal
);
