import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SiteActions from './site.actions';
import { Deserialize } from 'cerialize';
import {
  Site,
} from '../../models/site';
import { ApiService } from '../../services/api.service';
import { Store } from '@ngrx/store';
import { ColorTools } from '../../tools/color.tools';


@Injectable()
export class SiteEffects {

  loadSites$ = createEffect(() => {
    return this.actions$.pipe(ofType(SiteActions.loadSites),
      concatMap(({organizationId}) =>
      this.api.sites$(organizationId).pipe(
        map(sites => sites.map((site: any) =>  Deserialize(site, Site))),
        map(sites => ColorTools.setColorsForSites(sites)),
        map(sites => SiteActions.loadSitesSuccess({ sites })),
        catchError(error => of(SiteActions.loadSitesFailure({ error }))))
    ));
  });

  retrieveSite$ = createEffect(() => {
    return this.actions$.pipe(ofType(SiteActions.retrieveSite),
      concatMap(({organizationId, siteId}) =>
        this.api.site$(organizationId, siteId).pipe(
          map(site => ColorTools.setColorsForSite(Deserialize(site, Site))),
          map(site => {
            return {id: site.id, changes: site};
          }),
          map(update => SiteActions.retrieveSiteSuccess({ update })),
          catchError(error => of(SiteActions.retrieveSiteFailure({ error }))))
      ));
  });

  activateSite$ = createEffect(() => {
    return this.actions$.pipe(ofType(SiteActions.activateSite),
      concatMap(({organizationId, siteId}) =>
        this.api.activateSite$(organizationId, siteId).pipe(
          map(site => ColorTools.setColorsForSite(Deserialize(site, Site))),
          map(site => {
            return {id: site.id, changes: site};
          }),
          map(update => SiteActions.activateSiteSuccess({ update })),
          catchError(error => of(SiteActions.activateSiteFailure({ error }))))
      ));
  });

  deactivateSite$ = createEffect(() => {
    return this.actions$.pipe(ofType(SiteActions.deactivateSite),
      concatMap(({organizationId, siteId}) =>
        this.api.deactivateSite$(organizationId, siteId).pipe(
          map(site => ColorTools.setColorsForSite(Deserialize(site, Site))),
          map(site => {
            return {id: site.id, changes: site};
          }),
          map(update => SiteActions.deactivateSiteSuccess({ update })),
          catchError(error => of(SiteActions.deactivateSiteFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions, private store: Store, private api: ApiService) {}

}
