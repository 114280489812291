import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllMarkers
} from '../../../../features/marker/marker.selectors';
import { Marker } from '../../../../models/marker';
import { Observable } from 'rxjs';


@Component({
  selector: 'marker-list',
  templateUrl: './marker-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class MarkerListComponent {

  constructor(private store: Store) {}

  get markers$(): Observable<Array<Marker>> {
    return this.store.select(selectAllMarkers);
  }
}
