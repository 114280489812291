<div *ngIf="site" class="container">
  <div class="main">
    <button mat-icon-button
            matTooltip="{{'back_to_sites' | transloco}}"
            class="material-symbols-outlined"
            (click)="onClickDeselectSite()">
      arrow_back
    </button>
    <div>
      <mat-card-title>
        <span style="position: relative; font-weight: 600">
          {{site.name}}
          <div class="emergency-badge" [style.background-color]="site.textColor"></div>
        </span>
      </mat-card-title>
      <mat-card-subtitle>
        {{site | sector}}
      </mat-card-subtitle>
    </div>
  </div>
  <div class="acquisition-date">
    {{'latest_acquisition' | transloco}}: {{site.lastBatchDate | date:dateFormat}}
  </div>
  <div class="draw">
    <site-details-draw-toolbar [site]=site></site-details-draw-toolbar>
  </div>
  <div class=modules>
    <div *ngIf="(site | module: [inspectionModule.ARCAD]) && site.detectionSummary"
         class=module>
      <site-detection-summary [site]="site"></site-detection-summary>
      <density-chart [data]="site.detectionSummary.densities"
                     [label]="'density_chart_detections' | transloco"></density-chart>
    </div>
    <div *ngIf="(site | module: [inspectionModule.PERREAD]) && site.volumeSummary"
         class=module>
      <site-volume-summary [site]="site"></site-volume-summary>
      <density-chart [data]="site.volumeSummary.densities"
                     [label]="'density_chart_volumes' | transloco"></density-chart>
      <volume-threshold-form></volume-threshold-form>
    </div>
  </div>
</div>
