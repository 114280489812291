<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>warning</mat-icon>&nbsp;{{message}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="sub-title text">
      {{'leave_site_warning' | transloco}}
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(true)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
