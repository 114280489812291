import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as OrganizationActions from './organization.actions';
import { Deserialize } from 'cerialize';
import { Organization } from '../../models/organization';
import { ApiService } from '../../services/api.service';


@Injectable()
export class OrganizationEffects {

  loadOrganizations$ = createEffect(() => {
    return this.actions$.pipe(ofType(OrganizationActions.loadOrganizations), concatMap(() =>
      this.api.organizations$().pipe(
        map(organizations => organizations.map((organization: any) => Deserialize(organization, Organization))),
        map(organizations => OrganizationActions.loadOrganizationsSuccess({ organizations })),
        catchError(error => of(OrganizationActions.loadOrganizationsFailure({ error }))))
    ));
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
