import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { webSocket, WebSocketSubject, } from 'rxjs/webSocket';


@Injectable()
export class WsService {

  private static buildUrl(organizationId: number, module: string) {
    if (environment.websocket.startsWith('ws://') ||  environment.websocket.startsWith('wss://')) {
      return environment.websocket;
    }
    const protocol = (location.protocol == 'https:') ? 'wss' : 'ws'
    return protocol + "://" + location.host + "" + environment.websocket + '/' + organizationId + '/'+ module
  }

  static connectToChannel(organizationId: number, module: string): WebSocketSubject<any> {
    return webSocket({
      url: WsService.buildUrl(organizationId, module),
      openObserver: {
        next: () => {
          console.log('Connection to channel' + organizationId + '/' + module +' ok');
        }
      },
    });
  }
}
