export enum Color {
  PRIMARY = '#007ca6',
  ACCENT = '#e44812',
  ANALOGOUS_1 = '#00a67d',
  ANALOGOUS_2 = '#002aa6',
  TRIADIC_1 = '#2a00a6',
  TRIADIC_2 = '#fd0091',
  EMERGENCY_HIGH = '#DC2626',
  EMERGENCY_MEDIUM_HIGH = '#D97706',
  EMERGENCY_MEDIUM_LOW = '#d9c406',
  EMERGENCY_LOW = '#16A34A',
  UNAVAILABLE  = '#efefef'
}
