import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'site-activation-dialog',
  templateUrl: './site-activation.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class SiteActivationDialog {
  public siteName: string = '';
  constructor(public dialogRef: MatDialogRef<SiteActivationDialog>) {}
}
