
export class ExportTools {

  static exportJSON(filename: string, json: string) {
    const createEl = document.createElement('a');
    createEl.href = 'data:application/json;charset=utf-8,' + encodeURIComponent(json);
    createEl.download = filename.replace(/ /g,"_") + '.json';
    createEl.click();
    createEl.remove();
  }

  static exportDXF(filename: string, text: string) {
    const createEl = document.createElement('a');
    createEl.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
    createEl.download = filename.replace(/ /g,"_") + '.dxf';
    createEl.click();
    createEl.remove();
  }

  static exportResourceUrl(filename: string, url: string) {
    const createEl = document.createElement('a');
    createEl.href = url;
    createEl.download = filename;
    createEl.click();
    createEl.remove();
  }
}
