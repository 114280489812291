import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllCuts,
} from '../../../../features/cut/cut.selectors';
import { Cut } from '../../../../models/cut';
import { Observable } from 'rxjs';


@Component({
  selector: 'cut-list',
  templateUrl: './cut-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class CutListComponent {

  constructor(private store: Store) {}

  get cuts$(): Observable<Array<Cut>> {
    return this.store.select(selectAllCuts);
  }
}
