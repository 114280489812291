import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class ConfirmDialog {
  icon: string | undefined;
  title: string = '';
  subtitle: string | undefined;
  message: string = '';

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {}
}
