import { createAction, props } from '@ngrx/store';
import { Cut } from '../../models/cut';
import { Update } from '@ngrx/entity';

export const addCut = createAction('[Cut] Add Cut ', props<{ organizationId: string, siteId: string, point1: Array<number>, point2: Array<number> }>());
export const addCutSuccess = createAction('[Cut/API] Add Cut success', props<{ cut: Cut}>());
export const addCutFailure = createAction('[Cut/API] Add Cut failure', props<{ error: any }>());
export const updateCut = createAction('[Cut] Update Cut ', props<{ update: Update<any> }>());
export const deleteCut = createAction('[Cut] Delete Cut', props<{ id: string }>());
export const clearAllCuts = createAction('[Cut] Clear All Cuts');
export const selectCut = createAction('[Cut] Select Cut', props<{ id: string | undefined}>());
export const exportCutDXF = createAction('[Cut] Export Cut DXF', props<{ organizationId: string, siteId: string, cuts: Array<any>, slope: Array<Array<number>>, language_code: string}>());
export const exportCutDXFSuccess = createAction('[Cut/API] Export Cut DXFsuccess', props<{ dxf: string}>());
export const exportCutDXFFailure = createAction('[Cut/API] Export Cut DXF failure', props<{ error: any }>());
