import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SectorActions from './sector.actions';
import { Deserialize } from 'cerialize';
import { Sector } from '../../models/sector';
import { ApiService } from '../../services/api.service';


@Injectable()
export class SectorEffects {

  loadSectors$ = createEffect(() => {
    return this.actions$.pipe(ofType(SectorActions.loadSectors),
      concatMap(({organizationId}) =>
      this.api.sectors$(organizationId).pipe(
        map(sectors => sectors.map((sector: any) => Deserialize(sector, Sector))),
        map(sectors => SectorActions.loadSectorsSuccess({ sectors })),
        catchError(error => of(SectorActions.loadSectorsFailure({ error }))))
    ));
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
