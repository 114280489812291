import { Component, Input } from '@angular/core';
import { Site } from '../../../../models/site';


@Component({
  selector: 'site-volume-summary',
  templateUrl: './site-volume-summary.component.html'
})
export class SiteVolumeSummaryComponent {

  @Input({required: true}) site: Site | undefined;

  constructor() {

  }
}
