import {
  autoserialize,
  autoserializeAs,
  deserialize, deserializeAs,
  serialize
} from 'cerialize';
import {v4 as uuidv4} from 'uuid';

export class MarkerImage {
  @autoserializeAs("image_name") name!: string;
  @autoserializeAs("marker_pixel") position!: Array<number>;
  @autoserializeAs("center_distance") distance!: number;
}

export class MarkerBatchImages {
  @autoserialize batch!: string;
  @autoserializeAs(MarkerImage) data!: Array<MarkerImage>;
}

export class Marker {
  id: string;
  show: boolean;
  kind: string = 'marker';
  @serialize name: string;
  @serialize comment: string;
  @autoserialize point!: Array<number>;
  @autoserializeAs(MarkerBatchImages,"marker_images") markerBatchImages!: Array<MarkerBatchImages>;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;

  constructor() {
    this.id = uuidv4();
    this.name = '';
    this.comment = '';
    this.show = true;
  }

}
