import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as OrganizationActions from './organization.actions';
import { Organization } from '../../models/organization';


export interface State extends EntityState<Organization> {
  selectedOrganizationId: string | null;
}

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>();

export const initialState: State = adapter.getInitialState({selectedOrganizationId: null});

const organizationReducer = createReducer(
  initialState,
  on(OrganizationActions.selectOrganization, (state, { organizationId }) => {
    return { ...state, selectedOrganizationId: organizationId };
  }),
  on(OrganizationActions.loadOrganizations, (state) => {
    return state;
  }),
  on(OrganizationActions.loadOrganizationsSuccess, (state, { organizations }) => {
    return adapter.setAll(organizations, state);
  }),
  on(OrganizationActions.loadOrganizationsFailure, (state, { error }) => {
    return state;
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return organizationReducer(state, action);
}

export const getSelectedOrganizationId = (state: State) => state.selectedOrganizationId;



