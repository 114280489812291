import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { VolumeColorMode } from '../../../enums/volume';
import {
  selectVolumeColorMode
} from '../../../features/volume/volume.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { setVolumeColorMode } from '../../../features/volume/volume.actions';

@Component({
  selector: 'volume-display-mode-selector',
  templateUrl: './volume-display-mode-selector.component.html',
  styleUrls: ['./volume-display-mode-selector.component.scss'],
})
export class VolumeDisplayModeSelectorComponent {

  colorMode: VolumeColorMode = VolumeColorMode.COLOR_MODE_VOLUME_MOVED;

  constructor(private store: Store) {
    this.initHandleVolumeColorMode();
  }

  private initHandleVolumeColorMode() {
    this.store.select(selectVolumeColorMode)
      .pipe(takeUntilDestroyed())
      .subscribe((colorMode: any) => {
        this.colorMode = colorMode;
      });
  }

  onModeChanged(event: any) {
    this.store.dispatch(setVolumeColorMode({colorMode: event}));
  }

}
