<mat-card *ngIf="cut"
          style="cursor: pointer;"
          [style.border]=border
          (click)="onClickEdit(cut)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{cut.name}}</mat-card-title>
  </mat-card-header>
</mat-card>



