import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';
import { ActiveElement, Chart } from 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js/dist/types';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { selectCurrentCut } from '../../../features/cut/cut.selectors';
import { Cut, CutProfile } from '../../../models/cut';
import { Color } from '../../../enums/color';
import { getRelativePosition } from 'chart.js/helpers';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { selectUser } from '../../../features/user/user.selectors';



@Component({
  selector: 'cut-chart',
  template: '<canvas #canvas (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)"></canvas>',
  styles: ['canvas { width: 100%; height: 100%}']
})
export class CutChartComponent implements AfterViewInit {

  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement> | undefined;
  cut: Cut | null | undefined;
  @Output() slopeDragging: EventEmitter<Array<Array<number>> | undefined> = new EventEmitter();
  @Output() slopeHasChanged: EventEmitter<Array<Array<number>> | undefined> = new EventEmitter();
  private chart: any;
  private config: any;
  private isDragging: boolean = false;
  private slopeElement: ActiveElement | undefined;
  private slope: Array<Array<number>> | undefined;

  constructor(private store: Store, private translocoService: TranslocoService) {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectCurrentCut),
      this.store.select(selectUser)
    ]).pipe(takeUntilDestroyed())
      .subscribe(contents => {
        const site:any = contents[0];
        this.cut = contents[1];
        const user = contents[2];

        if (site && this.cut && user) {
          this.slope = (this.cut.slopeModified) ? Object.assign([], this.cut.slopeModified) : Object.assign([], this.cut.slope);
          // label: profile.batch == site.lastBatch ? 'Dernière acquisition' : 'Précédente acquisition',
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          let datasets = this.cut.profiles
            .sort((a, b) => (a.batch == site.lastBatch ? -1 : 1))
            .map((profile:CutProfile) => {
              const color = profile.batch == site.lastBatch ? Color.ANALOGOUS_1 : Color.ANALOGOUS_2;
              const line:any = {
                label: profile.batch == site.lastBatch ? datePipe.transform(new Date(site.lastBatchDate), dateFormat) : datePipe.transform(new Date(site.previousBatchDate), dateFormat) ,
                data: profile.dataset,
                pointStyle: false,
                fill: false,
                borderColor: color,
                backgroundColor: color,
              };
              return line;
            });

          const color = Color.TRIADIC_2;
          datasets.unshift({
            label: this.translocoService.translate("slope"),
            data: Object.assign([], this.slope),
            fill: false,
            pointStyle: "circle",
            pointRadius: 5,
            pointHitRadius: 10,
            pointHoverRadius: 10,
            pointFill: true,
            borderColor: color,
            backgroundColor: color,
            pointBackgroundColor: color,
          });

          const data: ChartData = {
            datasets
          }

          const options: ChartOptions = {
            onHover: (event: any, elements: ActiveElement[], chart: any) => {
              const filteredElements = elements?.filter((e) => e.datasetIndex == 0);
              this.slopeElement = (filteredElements?.length) ? filteredElements[0] : undefined;
              if (this.isDragging) {
                document.body.style.cursor = 'grabbing';
              }
              else {
                document.body.style.cursor = (this.slopeElement) ? 'grab' : 'default';
              }

            },
            plugins: {
              tooltip: {
                enabled: false,
              },
            },
            animation: {
              duration: 0
            },
            scales: {
              x:{
                type: 'linear',
              },
              y:{
                type: 'linear',
              },
            }
          }
          this.config = {
            type:'line',
            data,
            options,
          }
          this.draw();
        }
      });
  }

  private draw(): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        if (this.chart) this.chart.destroy();
        this.chart = new Chart(ctx, this.config);
        this.resize(this.chart);
      }
    }
  }

  private resize(chart: Chart): void {
    let xMin:number = 0;
    let yMin:number = 0;
    let xMax:number = 0;
    let yMax:number = 0;
    chart.config.data.datasets.forEach((dataset: any) => {
      dataset.data.forEach((point: any) => {
        const x = parseFloat(point[0]);
        const y = parseFloat(point[1]);
        xMin = (x < xMin) ? x : xMin;
        yMin = (y < yMin) ? y : yMin;
        xMax = (x > xMax) ? x : xMax;
        yMax = (y > yMax) ? y : yMax;
      });
    });

    const chartAreaWidth = chart.chartArea.width;
    const chartAreaHeight = chart.chartArea.height;
    let pxPerUnityX:number = chartAreaWidth / (Math.ceil(xMax) - xMin);
    let pxPerUnityY:number = chartAreaHeight / (Math.ceil(yMax) - yMin);

    let options:any = chart.config.options
    if (pxPerUnityX < pxPerUnityY) {
      options.scales.x.min = xMin;
      options.scales.x.max = Math.ceil(xMax);
      options.scales.y.min = yMin;
      options.scales.y.max = yMin + (chartAreaHeight / pxPerUnityX);
    } else {
      options.scales.x.min = xMin;
      options.scales.x.max = xMin + (chartAreaWidth / pxPerUnityY);
      options.scales.y.min = yMin;
      options.scales.y.max = Math.ceil(yMax);
    }
    chart.update();
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  canvasToImage(filename: string): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      let canvasUrl = canvas.toDataURL("image/png");
      const createEl = document.createElement('a');
      createEl.href = canvasUrl;
      createEl.download = filename.replace(/ /g,"_");
      createEl.click();
      createEl.remove();
    }
  }

  onMouseDown(event: any): void {
    if (this.slopeElement) {
      this.isDragging = true;
    }
  }

  onMouseUp(event: any): void {
    this.isDragging = false;
    document.body.style.cursor = 'default';
    this.slopeHasChanged.next(this.slope);
    this.resize(this.chart);
  }

  onMouseMove(event: any): void {
    if (this.isDragging && this.slopeElement) {
      const canvasPosition = getRelativePosition(event, this.chart);

      let valX = this.chart.scales.x.getValueForPixel(canvasPosition.x);
      let posX = (this.chart.scales.x.min <= valX && valX <= this.chart.scales.x.max) ? canvasPosition.x : this.slopeElement.element.x;

      let valY = this.chart.scales.y.getValueForPixel(canvasPosition.y);
      let posY = (this.chart.scales.x.min <= valY && valY <= this.chart.scales.y.max) ? canvasPosition.y : this.slopeElement.element.y;

      this.slopeElement.element.x = posX;
      this.slopeElement.element.y = posY;

      if (this.slope) {
        this.slope[this.slopeElement.index] = [valX, valY];
        this.slopeDragging.next(this.slope);
      }
    }
  }

}
