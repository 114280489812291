<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>delete</mat-icon>&nbsp;{{'delete' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{message}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(true)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
