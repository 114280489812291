import {
  Directive,
  ElementRef,
  OnInit,
} from '@angular/core';
import { CesiumTools } from '../../tools/cesium.tools';

@Directive({
  selector: '[cesium-minimap]'
})
export class CesiumMiniMapDirective implements OnInit {

  viewer!: any;

  constructor(private el: ElementRef) {  }

  ngOnInit() {
    const terrain = Cesium.Terrain.fromWorldTerrain()
    this.viewer = new Cesium.Viewer(this.el.nativeElement, {
      animation: false,
      baseLayerPicker: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      timeline: false,
      navigationHelpButton: false,
      orderIndependentTranslucency: true,
      fullscreenButton: false,
      homeButton: false,
      geocoder: false,
      skyBox: false,
      terrain: terrain
    });

    CesiumMiniMapDirective.configureScene(this.viewer);
    CesiumMiniMapDirective.configureCamera(this.viewer);
  }

  private static configureScene(viewer: any): void {
    viewer.scene.fxaa = false;
    viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
    viewer.scene.globe.translucency.enabled = true;
    viewer.scene.allowTextureFilterAnisotropic = false;
  }

  static configureCamera(viewer: any) {
    viewer.scene.screenSpaceCameraController.enableTilt = false;
    viewer.scene.screenSpaceCameraController.enableZoom = false;
  }

  setCameraPosition(position: any): void {
    const preset = CesiumTools.camera2DHPresetsFromPosition(position, 0);
    this.viewer.camera.flyTo(preset)
  }
}
