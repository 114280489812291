import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromConfig from './config.reducer';


export const selectConfigState = createFeatureSelector<fromConfig.State>('config');

export const selectConfigSideMode = createSelector(
  selectConfigState,
  state => state.sideMode
);

export const selectDisplaySitesFilters = createSelector(
  selectConfigState,
  state => state.displaySitesFilters
);

export const selectSitesFilters = createSelector(
  selectConfigState,
  state => state.sitesFilters
);

export const selectColorRampPositiveColors = createSelector(
  selectConfigState,
  state => state.colorRampPositiveColors
);
export const selectColorRampNegativeColors = createSelector(
  selectConfigState,
  state => state.colorRampNegativeColors
);
export const selectColorRampMin = createSelector(
  selectConfigState,
  state => state.colorRampMin
);
export const selectColorRampMax = createSelector(
  selectConfigState,
  state => state.colorRampMax
);
export const selectSiteDetailSelectedSection = createSelector(
  selectConfigState,
  state => state.siteDetailSelectedSection
);
export const selectSearchWorldText = createSelector(
  selectConfigState,
  state => state.searchWorldText
);
