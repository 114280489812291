import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FilterSlide } from '../../../models/filter-slide';


@Component({
  selector: 'filter-slider',
  templateUrl: './filter-slider.component.html',
  styleUrls: ['./filter-slider.component.scss'],
})
export class FilterSliderComponent {

  @Input() title: string | undefined;
  @Input({required: true}) data: FilterSlide | undefined;
  @Output() dataChange = new EventEmitter<FilterSlide | undefined>();

  get _start(): number {
    if(!this.data) return 0;
    return (this.data.start) ? this.data.start : this.data.min;
  }

  get _end(): number {
    if(!this.data) return 0;
    return (this.data.end) ? this.data.end : this.data.max;
  }

  updateStart(value: any) {
    if(!this.data) return;
    this.data.start = value;
    this.dataChange.next(this.data);
  }

  updateEnd(value: any) {
    if(!this.data) return;
    this.data.end = value;
    this.dataChange.next(this.data);
  }
}
