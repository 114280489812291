<mat-card>
  <mat-card-title>
    {{'configuration' | transloco}}
  </mat-card-title>
  <mat-card-content>
    {{'partial_configuration' | transloco}}
  </mat-card-content>
  <mat-card-actions>
    <button mat-button cdkFocusInitial (click)="dialogRef.close()">{{'i_understand' | transloco}}</button>
  </mat-card-actions>
</mat-card>
