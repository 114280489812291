import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromDetection from './detection.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromDetection.adapter.getSelectors();

export const selectDetectionState = createFeatureSelector<fromDetection.State>('detections');

export const selectDetectionIds = createSelector(
  selectDetectionState,
  selectIds
);
export const selectDetectionEntities = createSelector(
  selectDetectionState,
  selectEntities
);
export const selectAllDetections = createSelector(
  selectDetectionState,
  selectAll
);
export const selectDetectionTotal = createSelector(
  selectDetectionState,
  selectTotal
);

export const selectCurrentDetectionId = createSelector(
  selectDetectionState,
  fromDetection.getSelectedDetectionId
);

export const selectCurrentDetection = createSelector(
  selectDetectionEntities,
  selectCurrentDetectionId,
  (entities, id) => (id) ? entities[id] : null
);

export const selectDetectionEmergencyTemplate = createSelector(
  selectDetectionState,
  fromDetection.getDetectionEmergencyTemplate
);

export const selectDetectionTemplate = createSelector(
  selectDetectionState,
  fromDetection.getDetectionTemplate
);
