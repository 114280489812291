<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>border_color</mat-icon>&nbsp;{{'rename' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{message}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <div>
      <input matInput
             type="text"
             [placeholder]="placeholder"
             [(ngModel)]="name">
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(name)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
