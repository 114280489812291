<div class="container" @inOutAnimation>
  <mat-button-toggle-group #viewMode="matButtonToggleGroup"

                           [hideSingleSelectionIndicator]="true"
                           (change)="onModeChanged($event.value)"
                           [value]="this.cameraMode">
    <mat-button-toggle matTooltip="{{'3d_view' | transloco}}" value="3d">
      {{'3d_view' | transloco}}
    </mat-button-toggle>
    <mat-button-toggle matTooltip="{{'2dh_view_tooltip' | transloco}}" value="2dh">
      {{'2dh_view' | transloco}}
    </mat-button-toggle>
    <mat-button-toggle matTooltip="{{'2dv_view_tooltip' | transloco}}" value="2dv">
      {{'2dv_view' | transloco}}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <cesium-2dv-control [ngClass]="{'hide': cameraMode !== '2dv'}">
  </cesium-2dv-control>
</div>
