import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'leave-site-dialog',
  templateUrl: './leave-site.dialog.html',
  styleUrls: ['../dialog.scss', './leave-site.dialog.scss']
})
export class LeaveSiteDialog {
  constructor(public dialogRef: MatDialogRef<LeaveSiteDialog>) {}

  public message: string = '';
}
