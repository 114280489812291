<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>assignment</mat-icon>&nbsp;{{'generate_report' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{'report_select_formats' | transloco}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <div style="display: flex; flex-direction: column">
      <mat-checkbox *ngFor="let item of availableFormats"
                    color="primary"
                    [(ngModel)]="item.checked"
                    (change)="item.checked=$event.checked">
        {{item.name}}
      </mat-checkbox>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            [disabled]="!isValid"
            (click)="dialogRef.close(selectedFormats)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
