
export class FilterSlide {
  min: number = 0;
  max: number = 100;
  private _start: number | undefined;
  private _end: number | undefined;

  toJson(): any {
    return JSON.parse(JSON.stringify({min: this.min, max: this.max, start: this.start, end: this.end}));
  }

  get start(): number {
    return (this._start) ? this._start : this.min;
  }

  set start(value: number) {
    this._start = value;
  }

  get end(): number {
    return (this._end) ? this._end : this.max;
  }

  set end(value: number) {
    this._end = value;
  }
}
