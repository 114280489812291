import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Volume } from '../../../../models/volume';
import {
  selectCurrentVolume, selectVolumeEmergencyTemplate
} from '../../../../features/volume/volume.selectors';
import {
  addVolumeEmergency,
  selectVolume
} from '../../../../features/volume/volume.actions';
import { selectMarker } from '../../../../features/marker/marker.actions';
import { selectMeasure } from '../../../../features/measure/measure.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import {
  setCameraConfiguration,
  setCameraMode
} from '../../../../features/cesium/cesium.actions';
import { CameraMode } from '../../../../enums/camera';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, first } from 'rxjs';
import {
  EmergencyTemplate,
} from '../../../../models/emergency-template';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Site } from '../../../../models/site';
import { selectUser } from '../../../../features/user/user.selectors';
import { User } from '../../../../models/user';
import { EmergencyTools } from '../../../../tools/emergency.tools';
import { ItemNamePipe } from '../../../pipes/tools.pipe';


@Component({
  selector: 'volume-details',
  templateUrl: './volume-details.component.html',
  styleUrls: ['../details.component.scss', './volume-details.component.scss'],
})
export class VolumeDetailsComponents {

  volume: Volume | null | undefined;
  emergencyTemplate: EmergencyTemplate | undefined;
  emeregencyFormControl = new FormControl();
  form = new FormGroup({
    emergency: this.emeregencyFormControl
  });
  formHasChanged: boolean = false;

  constructor(private store: Store, private itemNamePipe: ItemNamePipe) {
    this.initHandleCurrentVolume();
    this.initHandleEmergencyTemplate();
  }

  private initHandleCurrentVolume(): void {
    this.store.select(selectCurrentVolume)
      .pipe(takeUntilDestroyed())
      .subscribe((volume: Volume | null | undefined) => {
        this.volume = volume;
        if (this.volume) {
          this.store.dispatch(selectMarker({id: undefined}));
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));

          if (this.volume.emergency) {
            this.emeregencyFormControl.setValue(this.volume.emergency.priority.toString());
          }

          this.form.valueChanges.subscribe((data: any) => {
            this.formHasChanged = (this.volume?.emergency) ? data['emergency'] != this.volume?.emergency.priority : data['emergency'] != 0;
          });
        }
      });
  }

  private initHandleEmergencyTemplate(): void {
    this.store.select(selectVolumeEmergencyTemplate)
      .pipe(takeUntilDestroyed())
      .subscribe((emergencyTemplate: EmergencyTemplate | undefined) => {
        this.emergencyTemplate = emergencyTemplate
      });
  }

  private updateEmergency(site: Site, user: User, volume: Volume): void {
    if (this.emergencyTemplate) {
      const etl = this.emergencyTemplate.labels
        .find(i => i.priority.toString() === this.emeregencyFormControl.value);
      if (etl) {
        this.store.dispatch(addVolumeEmergency({
          organizationId: site.organization,
          siteId: site.id,
          batchId: site.lastBatch,
          volumeId: volume.id,
          payload: {
            'creator': user.name,
            'emergency_level': etl.level,
            'emergency_label': etl.label,
            'emergency_priority': etl.priority
          }
        }));
      }
    }
  }

  emergencyLevelColor(level: number): string {
    return EmergencyTools.textColor(level);
  }

  onClickFlyTo(volume: Volume): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(volume.center, volume.cameraDirection, volume.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.store.dispatch(selectVolume({id: undefined}));
  }

  onClickExportDXF(volume: Volume): void {

  }

  onClickExportData(volume: Volume): void {
    const name = this.itemNamePipe.transform('volume', volume.id);
    ExportTools.exportJSON(name, JSON.stringify(volume));
  }

  onClickUpdate(volume: Volume): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectUser),
    ]).pipe(first())
      .subscribe(results => {
        const site: Site | null | undefined = results[0];
        const user: User | undefined = results[1];
        if (site && user && (!volume.emergency || volume.emergency.priority != this.emeregencyFormControl.value)) {
          this.updateEmergency(site, user, volume);
        }
      });
  }
}
