import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { SearchComponent } from '../search.component';
import {
  selectSearchSiteText
} from '../../../../features/site/site.selectors';
import { setSearchSiteText } from '../../../../features/site/site.actions';

@Component({
  selector: 'site-search',
  templateUrl: '../search.component.html',
  styleUrls: ['../search.component.scss']
})
export class SiteSearchComponent extends SearchComponent {

  constructor(store: Store) {
    super(store);
    this.initHandleSiteSearch();
  }

  private initHandleSiteSearch(): void {
    this.store.select(selectSearchSiteText)
      .pipe(first())
      .subscribe((text: string | undefined) => {
        this.searchText = (text) ? text : '';
      });
  }

  override onTextChange(text: string): void {
    this.store.dispatch(setSearchSiteText({text}));
  }
}
