import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromCesium from './cesium.reducer';


export const selectCesiumState = createFeatureSelector<fromCesium.State>('cesium');

export const selectCameraMode = createSelector(
  selectCesiumState,
  state => state.cameraMode
);

export const selectCameraConfiguration = createSelector(
  selectCesiumState,
  state => state.cameraConfiguration
);

export const selectMiniMapCameraConfiguration = createSelector(
  selectCesiumState,
  state => state.miniMapCameraConfiguration
);

export const selectIsMiniMapDragging = createSelector(
  selectCesiumState,
  state => state.isMiniMapDragging
);

export const selectMapHandleKeyboard = createSelector(
  selectCesiumState,
  state => state.mapHandleKeyboard
);

export const selectCameraRotationRate = createSelector(
  selectCesiumState,
  state => state.cameraRotationRate
);

export const selectDrawingMode = createSelector(
  selectCesiumState,
  state => state.drawingMode
);

export const selectHeightReference = createSelector(
  selectCesiumState,
  state => state.heightReference
);
