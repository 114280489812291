<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>delete</mat-icon>&nbsp;{{'delete' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{message}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <div>
      <mat-form-field>
        <mat-label>Motif</mat-label>
        <mat-select required [(value)]="status">
<!--          matTooltip="Description de Faux positif"-->
          <mat-option [value]="detectionStatusEnum.FALSE_POSITIVE">{{'false_positive' | transloco}}</mat-option>
<!--          matTooltip="Description de Non pertinent"-->
          <mat-option [value]="detectionStatusEnum.NOT_RELEVANT">{{'not_relevant' | transloco}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            [disabled]="!status"
            (click)="dialogRef.close(status)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
