import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as BatchActions from './batch.actions';
import { Batch } from '../../models/batch';


export interface State extends EntityState<Batch> {}

export const adapter: EntityAdapter<Batch> = createEntityAdapter<Batch>();

export const initialState: State = adapter.getInitialState();

const batchReducer = createReducer(
  initialState,
  on(BatchActions.loadBatchs, (state) => {
    return state;
  }),
  on(BatchActions.loadBatchsSuccess, (state, { batchs }) => {
    return adapter.setAll(batchs, state);
  }),
  on(BatchActions.loadBatchsFailure, (state, { error }) => {
    return state;
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return batchReducer(state, action);
}
