import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, defaultReturnItems: boolean = true, key: string = ''): any[] {
    if (!items) return [];
    if (!searchText) return (defaultReturnItems) ? items : [];

    return items.filter(item => {
      if (key.length) {
        return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
      }
      else {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
        });
      }
    });
  }
}
