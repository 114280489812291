<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>power_settings_new</mat-icon>&nbsp;{{'deactivate' | transloco}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    {{'deactivate_site_description' | transloco: {site: siteName} }}
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(true)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
