import { KeycloakService } from 'keycloak-angular';
import { ApiService } from '../services/api.service';
import { first, lastValueFrom } from 'rxjs';
import { Realm } from '../models/realm';
import { Deserialize } from 'cerialize';
import { map } from 'rxjs/operators';


export function initializeKeycloak(api: ApiService, keycloak: KeycloakService) {
  return async () => {
    const realm: Realm = await lastValueFrom(api.realm$().pipe(first(), map(r => Deserialize(r, Realm))));
    await keycloak.init({
      config: {
        url: realm.url,
        realm: realm.name,
        clientId: realm.clientId
      },
      initOptions: {
        onLoad: 'login-required',
      }
    });
  }
}
