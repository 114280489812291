<mat-card class="container" @inOutAnimation>
  <mat-card-content>
    <div class="selector">
      <volume-display-mode-selector *ngIf="emergencyTemplate"></volume-display-mode-selector>
    </div>

    <canvas #canvas></canvas>

    <div class="info" *ngIf="labelMin">
      <div>{{labelMin}}</div>
      <div>{{labelMax}}</div>
    </div>
  </mat-card-content>
</mat-card>
