<div *ngIf="(cuts$ | async) as cuts" class="container">
  <div *ngIf="cuts.length == 0" class="info">
    {{'used' | transloco}} <mat-icon>landscape</mat-icon>  {{'placeholder_list_cut' | transloco}}
  </div>
  <cut-card
    *ngFor="let item of cuts;"
    [cut]="item">
  </cut-card>
</div>


