import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { CesiumTools } from '../../tools/cesium.tools';
import { environment } from '../../../environments/environment';
import { CameraMode } from '../../enums/camera';
import { DataSourceType } from '../../enums/datasource-type';

@Directive({
  selector: '[cesium]'
})
export class CesiumDirective implements OnInit {

  viewer!: any;
  entityOverlay: any;
  private mode: string = CameraMode.MODE_3D;
  private flags:any = {
    looking: false,
    moveForward: false,
    moveBackward: false,
    moveUp: false,
    moveDown: false,
    moveLeft: false,
    moveRight: false,
    lookLeft: false,
    lookRight: false,
  };
  @Input() handleKeyboard: boolean = true;

  constructor(private el: ElementRef) {
    Cesium.Ion.defaultAccessToken = environment.cesiumToken;
  }

  ngOnInit() {
    // const terrain = Cesium.Terrain.fromWorldBathymetry({
    //   requestVertexNormals: true,
    // });
    const terrain = Cesium.Terrain.fromWorldTerrain();
    this.viewer = new Cesium.Viewer(this.el.nativeElement, {
      animation: false,
      baseLayerPicker: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      timeline: false,
      navigationHelpButton: false,
      orderIndependentTranslucency: false,
      fullscreenButton: false,
      homeButton: false,
      geocoder: false,
      skyBox: false,
      terrain,
    });

    // France
    const default_location = {lon: 2.2137, lat: 46.2276, alt: 19356453.597644374};
    this.viewer.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(default_location.lon, default_location.lat, default_location.alt)
    });


    this.entityOverlay = CesiumDirective.createEntityOverlayForViewer(this.viewer);

    CesiumDirective.configureScene(this.viewer);
    CesiumDirective.configureDataSources(this.viewer);
    CesiumTools.configureKeyBinding(this.viewer, (moveRate: number) => {
      this.keyBoardCallback(moveRate);
    });
  }

  private static configureScene(viewer: any) {
    viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
    viewer.scene.camera.percentageChanged = 0.001;
    viewer.scene.globe.translucency.enabled = true;
  }

  private static configureDataSources(viewer: any): void {
    viewer.dataSources.add(CesiumTools.createPinDataSource());
  }

  private static createEntityOverlayForViewer(viewer: any): any {
    const nameOverlay:any = document.createElement('div');
    viewer.container.appendChild(nameOverlay);
    nameOverlay.className = 'backdrop';
    nameOverlay.style.display = 'none';
    nameOverlay.style.position = 'absolute';
    nameOverlay.style.bottom = '0';
    nameOverlay.style.left = '0';
    nameOverlay.style['pointer-events'] = 'none';
    nameOverlay.style.padding = '8px';
    nameOverlay.style.backgroundColor = 'white';
    return nameOverlay;
  }

  private keyBoardCallback(moveRate:number) {
    const camera = this.viewer.camera;
    if (this.flags.lookLeft && this.mode == CameraMode.MODE_2DV) {
      camera.lookLeft(moveRate/10);
    }
    if (this.flags.lookRight && this.mode == CameraMode.MODE_2DV) {
      camera.lookRight(moveRate/10);
    }
    if (this.flags.moveForward) {
      switch (this.mode) {
        case CameraMode.MODE_2DH: {
          camera.moveUp(moveRate);
          break;
        }
        default: {
          camera.moveForward(moveRate);
          break;
        }
      }
    }
    if (this.flags.moveBackward) {
      switch (this.mode) {
        case CameraMode.MODE_2DH: {
          camera.moveDown(moveRate);
          break;
        }
        default: {
          camera.moveBackward(moveRate);
          break;
        }
      }
    }
    if (this.flags.moveUp) {
      camera.moveUp(moveRate);
    }
    if (this.flags.moveDown) {
      camera.moveDown(moveRate);
    }
    if (this.flags.moveLeft) {
      camera.moveLeft(moveRate);
    }
    if (this.flags.moveRight) {
      camera.moveRight(moveRate);
    }
  }

  @HostListener('document:keydown', ['$event'])
  private moveForward(e: KeyboardEvent) {
    if (!this.handleKeyboard) return
    e.preventDefault();
    const flagName = CesiumTools.getFlagForKeyCode(e.code);
    if (typeof flagName !== "undefined") {
      this.flags[flagName] = true;
    }
  }
  @HostListener('document:keyup', ['$event'])
  private moveBackward(e: KeyboardEvent) {
    if (!this.handleKeyboard) return
    e.preventDefault();
    const flagName = CesiumTools.getFlagForKeyCode(e.code);
    if (typeof flagName !== "undefined") {
      this.flags[flagName] = false;
    }
  }

  setMode(mode: string): void {
    this.mode = mode;
    switch (this.mode) {
      case CameraMode.MODE_2DH: {
        CesiumTools.enableCamera(this.viewer);
        this.viewer.scene.screenSpaceCameraController.enableTilt = false;
        break;
      }
      case CameraMode.MODE_2DV: {
        CesiumTools.disableCamera(this.viewer);
        break;
      }
      default: {
        CesiumTools.enableCamera(this.viewer);
        break;
      }
    }
  }

  displayDataSources(display: boolean):void {
    this.viewer.dataSources.getByName(DataSourceType.PIN)[0].show = display;
    this.viewer.dataSources.getByName(DataSourceType.SITE)[0].show = display;
  }
}
