import {
  AfterViewInit,
  Component,
  ElementRef, Input,
  ViewChild
} from '@angular/core';
import Chart, { ActiveElement } from 'chart.js/auto';
import {
  ChartData,
  ChartOptions
} from 'chart.js/dist/types';
import { getRelativePosition } from 'chart.js/helpers';
import { CesiumTools } from '../../../tools/cesium.tools';
import {
  setCameraConfiguration,
  setCameraMode
} from '../../../features/cesium/cesium.actions';
import { CameraMode } from '../../../enums/camera';
import { Store } from '@ngrx/store';
import { setSiteDensityHighlight } from '../../../features/site/site.actions';
import { DetectionDensity, VolumeDensity } from '../../../models/site';


@Component({
  selector: 'density-chart',
  template: '<canvas #canvas (mouseleave)="onMouseLeave($event)" (mouseout)="onMouseLeave($event)"></canvas>',
  styles: ['canvas { width: 100%; height: 100%}']
})
export class DensityChartComponent implements AfterViewInit {

  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement> | undefined;
  @Input() data: any;
  @Input() label: string = '';
  private chart: any;
  private config: any;

  constructor(private store: Store) {
  }

  private draw(): void {
    if (this.canvasRef?.nativeElement) {
      const data: ChartData = {
        labels: this.data.map((d: any) => d.index),
        datasets: [
          {
            label: this.label,
            data: this.data.map((d: any) => d.count),
            backgroundColor: this.data.map((d: any) => d.colorHex),
            borderColor: this.data.map((d: any) => d.colorHex)
          }
        ]
      }
      const options: ChartOptions = {
        onClick: (event: any, elements: ActiveElement[], chart: any) => {
          const canvasPosition = getRelativePosition(event, chart);
          const dataX = this.chart.scales.x.getValueForPixel(canvasPosition.x);
          const density: DetectionDensity | VolumeDensity = this.data[dataX];
          this.onClickFlyTo(density);
        },
        onHover: (event: any, elements: ActiveElement[], chart: any) => {
          event.native.target.style.cursor = 'pointer';
          const canvasPosition = getRelativePosition(event, chart);
          const dataX = this.chart.scales.x.getValueForPixel(canvasPosition.x);
          const density: DetectionDensity | VolumeDensity = this.data[dataX];
          this.store.dispatch(setSiteDensityHighlight({density}));
        },
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0
            }
          }
        },
        animation: {
          duration: 0
        }
      }
      this.config = {
        type:'bar',
        data,
        options,
      }
      const canvas = this.canvasRef.nativeElement;
      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        if (this.chart) this.chart.destroy();
        this.chart = new Chart(ctx, this.config);
      }
    }
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  onClickFlyTo(density: DetectionDensity | VolumeDensity): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(density.point, density.cameraDirection, density.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onMouseLeave(event: any) {
    this.store.dispatch(setSiteDensityHighlight({density: undefined}));
  }
}
