import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '../../models/site';
import { Store } from '@ngrx/store';
import { Sector } from '../../models/sector';
import { selectAllSectors } from '../../features/sector/sector.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Pipe({
  name: 'sector'
})
export class SectorPipe implements PipeTransform {

  private sectors: Array<Sector> | undefined;

  constructor(private store: Store) {
    this.initHandleSectors();
  }

  private initHandleSectors(): void {
    this.store.select(selectAllSectors)
      .pipe(takeUntilDestroyed())
      .subscribe((sectors: Array<Sector> | undefined) => {
        this.sectors = sectors;
      });
  }

  transform(site: Site): string | undefined {
    if (!site.sector || !this.sectors) return undefined;
    const filteredSectors = this.sectors.filter((item: Sector) => {
        return item.id == site.sector;
    });
    if(filteredSectors.length == 0) return undefined;
    return filteredSectors[0].name;
  }
}
