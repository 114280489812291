import { Component } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Actions, ofType } from '@ngrx/effects';
import {
  addMeasureArea,
  addMeasureDistance,
  addMeasureFailure,
  addMeasureSuccess
} from '../../../../features/measure/measure.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  addCut,
  addCutFailure,
  addCutSuccess
} from '../../../../features/cut/cut.actions';
import {
  addMarker,
  addMarkerFailure,
  addMarkerSuccess
} from '../../../../features/marker/marker.actions';
import {
  addDetection, addDetectionFailure, addDetectionSuccess
} from '../../../../features/detection/detection.actions';

@Component({
  selector: 'compute-loader',
  templateUrl: './compute-loader.component.html',
  styleUrls: ['./compute-loader.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      state('*', style({top: '-100px'})),
      state('in', style({
        top: '10px'
      })),
      state('out', style({
        top: '-100px'
      })),
      transition('in => out', [
        animate('150ms ease-out'),
      ]),
      transition('out => in', [
        animate('150ms ease-out'),
      ])
    ])
  ]
})
export class ComputeLoaderComponent {
  isComputing: boolean = false;

  constructor(private actions: Actions) {
    this.initHandleActions()
  }

  private initHandleActions() {
    this.actions.pipe(
      ofType(
        addDetection,
        addMeasureDistance,
        addMeasureArea,
        addCut,
        addMarker),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isComputing = true;
      });

    this.actions.pipe(
      ofType(
        addDetectionSuccess, addDetectionFailure,
        addMeasureSuccess, addMeasureFailure,
        addCutSuccess, addCutFailure,
        addMarkerSuccess, addMarkerFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isComputing = false;
      });
  }
}
