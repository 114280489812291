import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../../models/site';
import { CesiumMiniMapDirective } from '../../directives/cesium-minimap';
import * as Cesium from 'cesium';
import {
  setCameraRotationRate,
  setMiniMapCameraConfiguration,
  setMiniMapIsDragging
} from '../../../features/cesium/cesium.actions';
import { first } from 'rxjs';
import {
  selectCameraConfiguration,
  selectCameraMode,
  selectIsMiniMapDragging,
  selectMiniMapCameraConfiguration
} from '../../../features/cesium/cesium.selectors';
import { CameraConfiguration } from '../../../features/cesium/cesium.reducer';
import { CameraMode } from '../../../enums/camera';
import { Color } from '../../../enums/color';
import { SiteTools } from '../../../tools/site.tools';


@Component({
  selector: 'cesium-2dv-control',
  templateUrl: './cesium-2dv-control.component.html',
  styleUrls: ['./cesium-2dv-control.component.scss']
})
export class Cesium2dvControlComponent implements AfterViewInit {

  site: Site | null | undefined;
  height: number = 0;
  @ViewChild(CesiumMiniMapDirective) cesium: any;

  private entity!: Cesium.Entity | undefined;
  private isDragging: boolean = false;
  private cameraMode: CameraMode | undefined;

  constructor(private store: Store) {
    this.initHandleSite();
    this.initHandleCamera();
    this.initHandleIsDragging();
  }

  private initHandleSite() {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
          this.height = (this.site.height / 2.0) + this.site.altitude;
        }
      });
  }

  private initHandleCamera() {
    this.store.select(selectCameraMode)
      .pipe(takeUntilDestroyed())
      .subscribe( (mode: CameraMode) => {
        this.cameraMode = mode;
      });

    this.store.select(selectCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
        if (this.site && cameraConfiguration && this.entity?.position && !this.isDragging && this.cameraMode == CameraMode.MODE_2DV) {
          const position = cameraConfiguration.position;
          const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(
            Cesium.Cartesian3.fromElements(position.x, position.y, position.z)
          );
          const lon = Cesium.Math.toDegrees(carto.longitude);
          const lat = Cesium.Math.toDegrees(carto.latitude);
          this.height = carto.height;
          const miniMapCameraPosition = Cesium.Cartesian3.fromDegrees(
            lon, lat, this.site.altitude
          );

          const conf: CameraConfiguration = {
            position: miniMapCameraPosition,
            orientation:cameraConfiguration.orientation};
          this.store.dispatch(setMiniMapCameraConfiguration({cameraConfiguration: conf}));
        }
      });

    this.store.select(selectMiniMapCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
        if (cameraConfiguration && this.entity?.position && !this.isDragging && this.cameraMode == CameraMode.MODE_2DV) {
          // @ts-ignore
          this.entity.position.setValue(cameraConfiguration.position);
          try {
            this.entity.orientation = cameraConfiguration.orientation;
          }
          catch (e) {
            console.error(e);
          }
        }
      });
  }

  private initHandleIsDragging() {
    this.store.select(selectIsMiniMapDragging)
      .pipe(takeUntilDestroyed())
      .subscribe( (isDragging: boolean) => {
        this.isDragging = isDragging;
      });
  }

  ngAfterViewInit(): void {
    if (this.site && this.cesium) {
      this.configureCesiumMiniMap(
        SiteTools.longitude(this.site),
        SiteTools.latitude(this.site),
        this.site.altitude + 250
      );
    }
  }

  private configureCesiumMiniMap(longitude: number,
                                 latitude: number,
                                 height: number): void {

    this.cesium.setCameraPosition(Cesium.Cartesian3.fromDegrees(
      longitude,
      latitude,
      height
    ));

    const position = Cesium.Cartesian3.fromDegrees(longitude, latitude, 0)
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(-90, -90, 0));
    const point = {
      pixelSize: 10,
      heightReference : Cesium.HeightReference.CLAMP_TO_GROUND,
      color: new Cesium.CallbackProperty((time, result) => {
          if (this.entityPicked) {
            return Cesium.Color.fromCssColorString(Color.ACCENT);
          }
          return Cesium.Color.fromCssColorString(Color.PRIMARY);
        }, false),
      outlineColor: Cesium.Color.WHITE,
      outlineWidth: 2
      }

    this.entity = new Cesium.Entity({
      position,
      orientation,
      point,
    });

    this.cesium.viewer.entities.add(this.entity);

    const leftDown = this.leftDownHandler.bind(this)
    const leftUp = this.leftUpHandler.bind(this)
    const move = this.moveHandler.bind(this)
    // const rotate = this.rotateHandler.bind(this)
    const handler = this.cesium.viewer.screenSpaceEventHandler;
    handler.setInputAction(leftDown, Cesium.ScreenSpaceEventType.LEFT_DOWN);
    handler.setInputAction(leftUp, Cesium.ScreenSpaceEventType.LEFT_UP)
    handler.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    // handler.setInputAction(rotate, Cesium.ScreenSpaceEventType.WHEEL);
  }

  private get entityPicked(): boolean {
    return !this.cesium.viewer.scene.screenSpaceCameraController.enableInputs;
  }

  private leftDownHandler(e: any): void {
    if(this.cesium.viewer.scene.pick(e.position)) {
      this.store.dispatch(setMiniMapIsDragging({dragging: true}));
      this.cesium.viewer.scene.screenSpaceCameraController.enableInputs = false;
      CesiumMiniMapDirective.configureCamera(this.cesium.viewer);
    }
  }

  private leftUpHandler(e: any): void {
    this.cesium.viewer.scene.screenSpaceCameraController.enableInputs = true;
    this.store.dispatch(setMiniMapIsDragging({dragging: false}));
  }

  private moveHandler(e: any): void {
    if (!this.cesium.viewer.scene.screenSpaceCameraController.enableInputs) {
      const cartesianEndPosition = this.cesium.viewer.camera.pickEllipsoid(
        e.endPosition,
        this.cesium.viewer.scene.globe.ellipsoid
      );
      // @ts-ignore
      if (this.entity?.position) {
        // @ts-ignore
        this.entity.position.setValue(cartesianEndPosition);
        const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesianEndPosition);
        const lon = Cesium.Math.toDegrees(carto.longitude);
        const lat = Cesium.Math.toDegrees(carto.latitude);
        this.store.select(selectCameraConfiguration)
          .pipe(first())
          .subscribe( (cc: any) => {
            if (cc) {
              const cameraConfiguration = {
                position: Cesium.Cartesian3.fromDegrees(lon, lat, this.height),
                orientation: cc.orientation
              }
              this.store.dispatch(setMiniMapCameraConfiguration({cameraConfiguration}));
            }
          });
      }
    }
  }

  private rotateHandler(e: any):void {
    // @ts-ignore
    if(this.entity?.position) {
      const rotationRate: number = e / 360;
      this.store.dispatch(setCameraRotationRate({rotationRate}));
      const position: any = this.entity.position.getValue(this.cesium.viewer.clock.currentTime);
      const headingPitchRoll = new Cesium.HeadingPitchRoll(rotationRate, -90, 0);
      // @ts-ignore
      this.entity.orientation = Cesium.Transforms.headingPitchRollQuaternion(position, headingPitchRoll);
      const miniMapCameraConfiguration: CameraConfiguration = {
        position,
        orientation: {
          heading: headingPitchRoll.heading,
          pitch: 0,
          roll: 0,
        }};
      this.store.dispatch(setMiniMapCameraConfiguration({cameraConfiguration: miniMapCameraConfiguration}));
    }
  }
}
