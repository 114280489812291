import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as MarkerActions from './marker.actions';
import { Deserialize } from 'cerialize';
import { Marker } from '../../models/marker';
import { ApiService } from '../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';


@Injectable()
export class MarkerEffects {

  addMarker$ = createEffect(() => {
    return this.actions$.pipe(ofType(MarkerActions.addMarker),
      concatMap(({organizationId, siteId, point, cameraPosition}) =>
      this.api.marker$(organizationId, siteId, point, cameraPosition).pipe(
        map(marker => {
          const obj: Marker = Deserialize(marker, Marker);
          obj.name = this.translocoService.translate('marker') + " " + obj.id.slice(0,4).toUpperCase();
          return obj;
        }),
        map(marker => MarkerActions.addMarkerSuccess({ marker })),
        catchError(error => of(MarkerActions.addMarkerFailure({ error }))))
    ));
  });

  constructor(private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
