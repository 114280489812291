import { environment } from '../../environments/environment';
import { Site } from '../models/site';


export class SiteTools {
  static longitude(site: Site): number {
    if(site.pin) {
      const position = Cesium.Cartesian3.fromArray(site.pin);
      const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
      return Cesium.Math.toDegrees(carto.longitude);
    }
    return 0;
  }

  static latitude(site: Site): number {
    if(site.pin) {
      const position = Cesium.Cartesian3.fromArray(site.pin);
      const carto = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
      return Cesium.Math.toDegrees(carto.latitude);
    }
    return 0;
  }

  static tilesUrl(site: Site): string | undefined {
    if(site.lastBatch) {
      return environment.tilesUrl + '/' + site.lastBatch + '/tileset.json';
    }
    return undefined;
  }

  static previousTilesUrl(site: Site): string | undefined {
    if(site.previousBatch) {
      return environment.tilesUrl + '/' + site.previousBatch + '/tileset.json';
    }
    return undefined;
  }
}
