import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../../models/site';
import {
  selectAllSites,
  selectSearchSiteText
} from '../../../features/site/site.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  loadSites, loadSitesFailure,
  loadSitesSuccess
} from '../../../features/site/site.actions';

@Component({
  selector: 'site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent {

  sites: Array<Site> = [];
  searchText: string = '';
  isLoading: boolean = true;

  constructor(private store: Store, private actions: Actions) {
    this.store.select(selectAllSites)
      .pipe(takeUntilDestroyed())
      .subscribe((sites: Array<Site> | undefined) => {
        this.sites = (sites) ? sites : [];
        this.isLoading = this.sites.length == 0;
      });
    this.store.select(selectSearchSiteText)
      .pipe(takeUntilDestroyed())
      .subscribe((text: string | undefined) => {
        this.searchText = (text) ? text : '';
      });

    this.actions.pipe(ofType(loadSites), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadSitesSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(loadSitesFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
  }
}
