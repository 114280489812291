import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { setMapHandleKeyboard } from '../../../features/cesium/cesium.actions';


@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  searchText: string = '';
  @Input() placeholder: string = '';

  constructor(public store: Store) {

  }

  onInputHandleFocus(event: any) {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }
  onInputLeaveFocus(event: any) {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onTextChange(text: string): void {

  }

}
