import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Detection } from '../../../../models/detection';
import { SearchPipe } from '../../../pipes/search.pipe';
import {
  selectAllDetections
} from '../../../../features/detection/detection.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  loadDetections,
  loadDetectionsFailure,
  loadDetectionsSuccess
} from '../../../../features/detection/detection.actions';

@Component({
  selector: 'detection-list',
  templateUrl: './detection-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class DetectionListComponent {

  detections: Array<Detection> = [];
  isLoading: boolean = true;

  constructor(private store: Store, private actions: Actions, private search: SearchPipe) {
    this.store.select(selectAllDetections)
      .pipe(takeUntilDestroyed())
      .subscribe((detections: Array<Detection> | null | undefined) => {
        if (detections) {
          this.detections = detections;
        } else {
          this.detections = [];
        }
        this.isLoading = this.detections.length == 0;
      });

    this.actions.pipe(ofType(loadDetections), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadDetectionsSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(loadDetectionsFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
  }
}
