import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'site-deactivation-dialog',
  templateUrl: './site-deactivation.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class SiteDeactivationDialog {
  public siteName: string = '';
  constructor(public dialogRef: MatDialogRef<SiteDeactivationDialog>) {}
}
