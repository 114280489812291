import { CameraConfiguration } from '../features/cesium/cesium.reducer';

export class CesiumTools {

  static cameraOrientationBetweenTwoPoints(pointA: any, pointB: any): any {
    const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
    const positionVector = Cesium.Cartesian3.subtract(pointB, pointA, new Cesium.Cartesian3());
    const vector = Cesium.Matrix4.multiplyByPointAsVector(Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()), positionVector, new Cesium.Cartesian3());
    const direction = Cesium.Cartesian3.normalize(vector, new Cesium.Cartesian3());
    const heading = Math.atan2(direction.y,direction.x) - Cesium.Math.PI_OVER_TWO;
    return {
      heading: Cesium.Math.TWO_PI-Cesium.Math.zeroToTwoPi(heading),
      pitch: Cesium.Math.PI_OVER_TWO-Cesium.Math.acosClamped(direction.z),
      roll: 0
    };
  }

  static cameraConfigurationFromTargetAndDirection(target: Array<number>, camDirection: Array<number>, maxLength: number, coeff: number = 1): CameraConfiguration | undefined {
    if (target.length != camDirection.length) return;
    const cameraCoords = target.map((val: number, index: number) => val + (camDirection[index] * coeff * maxLength));
    const origin = Cesium.Cartesian3.fromArray(cameraCoords);
    const position = Cesium.Cartesian3.fromArray(target);
    const orientation = CesiumTools.cameraOrientationBetweenTwoPoints(origin, position);
    return {
      position: origin,
      orientation
    };
  }

  static cameraFromCameraConfiguration(cameraConfiguration: CameraConfiguration,
                                       duration: number = 1): any {
    return {
      'duration': duration,
      'destination': cameraConfiguration.position,
      'orientation': cameraConfiguration.orientation
    }
  }

  static camera3DPresetsFromPosition(position: any,
                                     duration: number = 1,
                                     heading: number = 0,
                                     picth: number = -45,
                                     roll: number = 0): any {
    const offset = new Cesium.Cartesian3(1000, 0, 0);
    return {
      'duration': duration,
      'destination': Cesium.Cartesian3.add(position, offset, position),
      'orientation': {
        heading: Cesium.Math.toRadians(heading),
        pitch: Cesium.Math.toRadians(picth),
        roll: Cesium.Math.toRadians(roll),
      }
    }
  }

  static camera2DHPresetsFromPosition(position: any,
                                      duration: number = 1,
                                      heading: number = 0,
                                      picth: number = -90,
                                      roll: number = 0): any {
    return {
      'duration': duration,
      'destination': position,
      'orientation': {
        heading: Cesium.Math.toRadians(heading),
        pitch: Cesium.Math.toRadians(picth),
        roll: Cesium.Math.toRadians(roll),
      }
    }
  }

  static camera2DVPresetsFromPosition(position: any,
                                      duration: number = 1,
                                      heading: number = 0,
                                      picth: number = 0,
                                      roll: number = 0): any {
    return {
      'duration': duration,
      'destination': position,
      'orientation': {
        heading: Cesium.Math.toRadians(heading),
        pitch: Cesium.Math.toRadians(picth),
        roll: Cesium.Math.toRadians(roll),
      }
    }
  }

  static cameraHeadingPitchRoll(heading: number = 0,
                                picth: number = 0,
                                roll: number = 0): any {
    return {
      heading: heading,
      pitch: picth,
      roll: roll,
    }
  }

  static createPinDataSource(): any {
    const pinBuilder = new Cesium.PinBuilder();
    const pin10 = pinBuilder
        .fromText('10+', Cesium.Color.BLUE, 48)
        .toDataURL();
    const pin5 = pinBuilder
        .fromText('5+', Cesium.Color.BLUE, 48)
        .toDataURL();
    const pin4 = pinBuilder
        .fromText('4+', Cesium.Color.BLUE, 48)
        .toDataURL();
    const pin3 = pinBuilder
        .fromText('3+', Cesium.Color.BLUE, 48)
        .toDataURL();
    const pin2 = pinBuilder
        .fromText('2+', Cesium.Color.BLUE, 48)
        .toDataURL();
    const singleDigitPins = new Array(8);

    const datasource = new Cesium.CustomDataSource('pin');
    datasource.clustering.enabled = true;
    datasource.clustering.pixelRange = 10;
    datasource.clustering.minimumClusterSize = 2;
    datasource.clustering.clusterEvent.addEventListener((clusteredEntities: any, cluster: any) => {
        cluster.label.show = false;
        cluster.billboard.show = true;
        cluster.billboard.id = cluster.label.id;
        cluster.billboard.verticalOrigin =
          Cesium.VerticalOrigin.BOTTOM;

        if (clusteredEntities.length >= 10) {
          cluster.billboard.image = pin10;
        } else if (clusteredEntities.length >= 5) {
          cluster.billboard.image = pin5;
        } else if (clusteredEntities.length >= 4) {
          cluster.billboard.image = pin4;
        } else if (clusteredEntities.length >= 3) {
          cluster.billboard.image = pin3;
        } else if (clusteredEntities.length >= 2) {
          cluster.billboard.image = pin2;
        } else {
          cluster.billboard.image = singleDigitPins[clusteredEntities.length - 2];
        }
      }
    );
    return datasource;
  }


  static disableCamera(viewer: any): void {
    viewer.scene.screenSpaceCameraController.enableInputs = false;
    viewer.scene.screenSpaceCameraController.enableLook = false;
    viewer.scene.screenSpaceCameraController.enableRotate = false;
    viewer.scene.screenSpaceCameraController.enableTilt = false;
    viewer.scene.screenSpaceCameraController.enableZoom = false;
  }

  static enableCamera(viewer: any): void {
    viewer.scene.screenSpaceCameraController.enableInputs = true;
    viewer.scene.screenSpaceCameraController.enableLook = true;
    viewer.scene.screenSpaceCameraController.enableRotate = true;
    viewer.scene.screenSpaceCameraController.enableTilt = true;
    viewer.scene.screenSpaceCameraController.enableZoom = true;
  }

  static configureKeyBinding(viewer: any, callback:any): void {
    viewer.clock.onTick.addEventListener((clock: any) => {
      const camera = viewer.camera;
      const scene = viewer.scene;
      const ellipsoid = scene.globe.ellipsoid;

      // Change movement speed based on the distance of the camera to the surface of the ellipsoid.
      const cameraHeight = ellipsoid.cartesianToCartographic(
        camera.position
      ).height;
      const moveRate = cameraHeight / 100.0;

      callback(moveRate);
    });
  }

  static getFlagForKeyCode(code: string) {
    switch (code) {
      case "KeyW":
        return "moveForward";
      case "KeyS":
        return "moveBackward";
      case "KeyQ":
        return "lookLeft";
      case "KeyE":
        return "lookRight";
      case "KeyD":
        return "moveRight";
      case "KeyA":
        return "moveLeft";
      case "PageUp": case "Space":
        return "moveUp";
      case "PageDown": case "ShiftLeft":
        return "moveDown";
      default:
        return undefined;
    }
  }

  static getOrCreateDataSource(viewer:any, name: string): any {
    const dataSources = viewer.dataSources.getByName(name);
    if (dataSources.length) {
      return dataSources[0];
    }else {
      const datasource = new Cesium.CustomDataSource(name);
      viewer.dataSources.add(datasource);
      return datasource;
    }
  }

  static retrievePrimitiveByEntityIdFromCollection(collection: any, id: any): any {
    let p = undefined;
      for(let i = 0 ; i < collection.length ; i++) {
        const primitive = collection.get(i);
        if (primitive.entity.id == id) {
          p = primitive;
          break;
        }
      }
      return p
  }

  static faceBoxGeometries(modelMatrix: any, dimensions: any, borderWidth: number, color: any): Array<any> {

    const geometryInstances = [];

    // -----------------------------------
    // Center Up
    const centerUp = new Cesium.Cartesian4(0, 0, dimensions.z/2, 1);
    let translationMatrixUp = new Cesium.Matrix4();
    Cesium.Matrix4.fromTranslation(centerUp, translationMatrixUp);
    Cesium.Matrix4.multiply(modelMatrix, translationMatrixUp, translationMatrixUp);
    const geometryUp = Cesium.BoxGeometry.fromDimensions({
      dimensions: new Cesium.Cartesian3(dimensions.x + borderWidth, dimensions.y + borderWidth, borderWidth),
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
    });
    geometryInstances.push(
      new Cesium.GeometryInstance({
        geometry : geometryUp,
        modelMatrix : translationMatrixUp,
        attributes : { color }
      })
    );

    // -----------------------------------
    // Center Down
    const centerDown = new Cesium.Cartesian4(0, 0, -dimensions.z/2, 1);
    let translationMatrixDown = new Cesium.Matrix4();
    Cesium.Matrix4.fromTranslation(centerDown, translationMatrixDown);
    Cesium.Matrix4.multiply(modelMatrix, translationMatrixDown, translationMatrixDown);
    const geometryDown = Cesium.BoxGeometry.fromDimensions({
      dimensions: new Cesium.Cartesian3(dimensions.x +borderWidth, dimensions.y + borderWidth, borderWidth),
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
    });
    geometryInstances.push(new Cesium.GeometryInstance({
      geometry : geometryDown,
      modelMatrix : translationMatrixDown,
      attributes : { color }
    }));

    // -----------------------------------
    // Center Left
    const centerLeft = new Cesium.Cartesian4(-dimensions.x/2, 0, 0, 1);
    let translationMatrixLeft = new Cesium.Matrix4();
    Cesium.Matrix4.fromTranslation(centerLeft, translationMatrixLeft);
    Cesium.Matrix4.multiply(modelMatrix, translationMatrixLeft, translationMatrixLeft);
    const geometryLeft = Cesium.BoxGeometry.fromDimensions({
      dimensions: new Cesium.Cartesian3(borderWidth, dimensions.y + borderWidth, dimensions.z + borderWidth),
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
    });
    geometryInstances.push(new Cesium.GeometryInstance({
      geometry : geometryLeft,
      modelMatrix : translationMatrixLeft,
      attributes : { color }
    }));

    // -----------------------------------
    // Center Right
    const centerRight = new Cesium.Cartesian4(dimensions.x/2, 0, 0, 1);
    let translationMatrixRight = new Cesium.Matrix4();
    Cesium.Matrix4.fromTranslation(centerRight, translationMatrixRight);
    Cesium.Matrix4.multiply(modelMatrix, translationMatrixRight, translationMatrixRight);
    const geometryRight = Cesium.BoxGeometry.fromDimensions({
      dimensions: new Cesium.Cartesian3(borderWidth, dimensions.y + borderWidth, dimensions.z + borderWidth),
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
    });
    geometryInstances.push(new Cesium.GeometryInstance({
      geometry : geometryRight,
      modelMatrix : translationMatrixRight,
      attributes : { color }
    }));

    // -----------------------------------
    // Center Front
    const centerFront = new Cesium.Cartesian4(0, dimensions.y/2, 0, 1);
    let translationMatrixFront = new Cesium.Matrix4();
    Cesium.Matrix4.fromTranslation(centerFront, translationMatrixFront);
    Cesium.Matrix4.multiply(modelMatrix, translationMatrixFront, translationMatrixFront);
    const geometryFront = Cesium.BoxGeometry.fromDimensions({
      dimensions: new Cesium.Cartesian3(dimensions.x + borderWidth, borderWidth, dimensions.z + borderWidth),
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
    });
    geometryInstances.push(new Cesium.GeometryInstance({
      geometry : geometryFront,
      modelMatrix : translationMatrixFront,
      attributes : { color }
    }));

    // -----------------------------------
    // Center Back
    const centerBack = new Cesium.Cartesian4(0, -dimensions.y/2, 0, 1);
    let translationMatrixBack = new Cesium.Matrix4();
    Cesium.Matrix4.fromTranslation(centerBack, translationMatrixBack);
    Cesium.Matrix4.multiply(modelMatrix, translationMatrixBack, translationMatrixBack);
    const geometryBack = Cesium.BoxGeometry.fromDimensions({
      dimensions: new Cesium.Cartesian3(dimensions.x + borderWidth, borderWidth, dimensions.z + borderWidth),
      vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
    });
    geometryInstances.push(new Cesium.GeometryInstance({
      geometry : geometryBack,
      modelMatrix : translationMatrixBack,
      attributes : { color }
    }));

    return geometryInstances;
  }


  static cartesian3ToArray(point: any):Array<number> {
    return [point.x, point.y, point.z];
  }

  static cartesian3ArrayToArray(points: Array<any>):Array<Array<number>> {
    return points.map(point => CesiumTools.cartesian3ToArray(point));
  }
}
