import {
  Component, EventEmitter, Output,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';
import {
  selectMeasureTotal
} from '../../../features/measure/measure.selectors';
import { selectCutTotal } from '../../../features/cut/cut.selectors';
import { selectMarkerTotal } from '../../../features/marker/marker.selectors';
import { combineLatest, first } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  LeaveSiteDialog
} from '../../../shared/dialogs/leave-site-dialog/leave-site.dialog';
import { User } from '../../../models/user';
import { getUser } from '../../../features/user/user.actions';
import { selectUser } from '../../../features/user/user.selectors';



@Component({
  selector: 'site-details-header',
  templateUrl: './site-details-header.component.html',
  styleUrls: ['./site-details-header.component.scss']
})
export class SiteDetailsHeaderComponent {

  site: Site | null | undefined;
  user: User | null | undefined;
  inspectionModule = InspectionModule;
  @Output() close: EventEmitter<any> = new EventEmitter()

  constructor(private store: Store, private dialog: MatDialog) {
    this.initHandleSite();
    this.initHandleUser();
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
        }
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  onClickDeselectSite() {
    combineLatest([
      this.store.select(selectMeasureTotal),
      this.store.select(selectCutTotal),
      this.store.select(selectMarkerTotal)
    ]).pipe(first())
      .subscribe((results: any) => {
      const countMeasure: number = results[0];
      const countCut: number = results[1];
      const countMarker: number = results[2];
      if (countMeasure > 0 || countCut > 0 || countMarker > 0) {
        const dialogRef = this.dialog.open(LeaveSiteDialog, {
          disableClose: false
        });
        dialogRef.componentInstance.message = (this.site) ? this.site.name : '';
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.close.next(this.site);
          }
        });
      }
      else {
        this.close.next(this.site);
      }
    });
  }

  get dateFormat() {
    if(this.user) {
      return (this.user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }
    return 'MM/dd/yyyy';
  }

}
