<mat-card>
  <div class="search-bar">
    <input matInput
           type="text"
           placeholder="{{placeholder}}"
           [matAutocomplete]="auto"
           [(ngModel)]="searchText"
           (input)="onTextChange(searchText)"
           (focusin)="onInputHandleFocus($event)"
           (focusout)="onInputLeaveFocus($event)">
    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="onSelectItem($event)">
      <mat-option *ngFor="let tag of searchResults" [value]="tag">
        {{tag.name}}
      </mat-option>
    </mat-autocomplete>
  </div>
</mat-card>

