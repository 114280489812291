import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DetectionStatus } from '../../../enums/detection';


@Component({
  selector: 'delete-detection-dialog',
  templateUrl: './delete-detection.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class DeleteDetectionDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDetectionDialog>) {}

  message:string = '';
  detectionStatusEnum = DetectionStatus;
  status: number | undefined;
}
