import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromMarker from './marker.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromMarker.adapter.getSelectors();

export const selectMarkerState = createFeatureSelector<fromMarker.State>('markers');

export const selectMarkerIds = createSelector(
  selectMarkerState,
  selectIds
);
export const selectMarkerEntities = createSelector(
  selectMarkerState,
  selectEntities
);
export const selectAllMarkers = createSelector(
  selectMarkerState,
  selectAll
);
export const selectMarkerTotal = createSelector(
  selectMarkerState,
  selectTotal
);


export const selectCurrentMarkerId = createSelector(
  selectMarkerState,
  fromMarker.getSelectedMarkerId
);

export const selectCurrentMarker = createSelector(
  selectMarkerEntities,
  selectCurrentMarkerId,
  (entities, id) => (id) ? entities[id] : null
);
