import { Action, createReducer, on } from '@ngrx/store';
import * as CesiumActions from './cesium.actions';
import { CameraMode } from '../../enums/camera';
import { DrawingMode } from '../../enums/drawing-mode';
import * as Cesium from 'cesium';



export interface CameraConfiguration {
  position: any | undefined;
  orientation: any | undefined;
}

export interface State {
  cameraMode: CameraMode;
  cameraConfiguration: CameraConfiguration | undefined
  miniMapCameraConfiguration: CameraConfiguration | undefined
  isMiniMapDragging: boolean;
  isMiniMapRotating: boolean;
  mapHandleKeyboard: boolean;
  cameraRotationRate: number | undefined;
  drawingMode: DrawingMode | undefined;
  heightReference: number;
}

export const initialState: State = {
  cameraMode: CameraMode.MODE_3D,
  cameraConfiguration: undefined,
  miniMapCameraConfiguration: undefined,
  isMiniMapDragging: false,
  isMiniMapRotating: false,
  mapHandleKeyboard: true,
  cameraRotationRate: undefined,
  drawingMode: undefined,
  heightReference:  Cesium.HeightReference.CLAMP_TO_GROUND
};


const cesiumReducer = createReducer(
  initialState,
  on(CesiumActions.setCameraMode, (state, { cameraMode }) => {
    return { ...state, cameraMode: cameraMode };
  }),
  on(CesiumActions.setCameraConfiguration, (state, { cameraConfiguration }) => {
    return { ...state, cameraConfiguration: cameraConfiguration };
  }),
  on(CesiumActions.setMiniMapCameraConfiguration, (state, { cameraConfiguration }) => {
    return { ...state, miniMapCameraConfiguration: cameraConfiguration };
  }),
  on(CesiumActions.setMiniMapIsDragging, (state, { dragging }) => {
    return { ...state, isMiniMapDragging: dragging };
  }),
  on(CesiumActions.setMapHandleKeyboard, (state, { mapHandleKeyboard }) => {
    return { ...state, mapHandleKeyboard: mapHandleKeyboard };
  }),
  on(CesiumActions.setCameraRotationRate, (state, { rotationRate }) => {
    return { ...state, cameraRotationRate: rotationRate };
  }),
  on(CesiumActions.setDrawingMode, (state, { drawingMode }) => {
    return { ...state, drawingMode: drawingMode };
  }),
  on(CesiumActions.setHeightReference, (state, { heightReference }) => {
    return { ...state, heightReference: heightReference };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return cesiumReducer(state, action);
}
