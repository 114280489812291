import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DetectionDensity, Site, VolumeDensity } from '../../models/site';
import {
  selectAllSites,
  selectCurrentSite,
  selectSearchSiteText,
  selectSiteDensityHighlight
} from '../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CesiumDirective } from '../../shared/directives/cesium';
import { combineLatest, first } from 'rxjs';
import { CameraMode } from '../../enums/camera';
import {
  selectCameraConfiguration,
  selectCameraMode,
  selectCameraRotationRate,
  selectDrawingMode,
  selectIsMiniMapDragging,
  selectMapHandleKeyboard,
  selectMiniMapCameraConfiguration,
} from '../../features/cesium/cesium.selectors';
import {
  setCameraConfiguration,
  setCameraMode,
  setDrawingMode,
  setMiniMapCameraConfiguration,
} from '../../features/cesium/cesium.actions';
import { CesiumTools } from '../../tools/cesium.tools';
import {
  selectAllDetections, selectCurrentDetection
} from '../../features/detection/detection.selectors';
import { Detection } from '../../models/detection';
import { CameraConfiguration } from '../../features/cesium/cesium.reducer';
import {
  addMeasureArea,
  addMeasureDistance,
  addMeasureFailure,
  addMeasureSuccess,
  clearAllMeasures,
  selectMeasure
} from '../../features/measure/measure.actions';
import {
  addDetection,
  addDetectionEmergencyFailure,
  addDetectionEmergencySuccess,
  addDetectionFailure,
  addDetectionSuccess,
  clearAllDetections,
  clearDetectionEmergencyTemplate,
  clearDetectionTemplate,
  deleteDetectionFailure,
  deleteDetectionSuccess,
  loadDetectionEmergencyTemplate,
  loadDetections, loadDetectionsSuccess,
  loadDetectionTemplate,
  selectDetection,
  updateDetectionFailure,
  updateDetectionSuccess
} from '../../features/detection/detection.actions';
import {
  addVolumeEmergencyFailure,
  addVolumeEmergencySuccess,
  clearAllVolumes,
  clearVolumeEmergencyTemplate,
  loadVolumeEmergencyTemplate,
  loadVolumes, loadVolumesSuccess,
  selectVolume,
  setVolumeColorMode
} from '../../features/volume/volume.actions';
import {
  addCut,
  addCutFailure,
  addCutSuccess,
  clearAllCuts,
  selectCut
} from '../../features/cut/cut.actions';
import {
  addMarker,
  addMarkerFailure,
  addMarkerSuccess,
  clearAllMarkers,
  selectMarker
} from '../../features/marker/marker.actions';
import { Volume } from '../../models/volume';
import { selectAllVolumes, } from '../../features/volume/volume.selectors';
import { OverlayService } from '../../services/overlay.service';
import { CesiumEntityService } from '../../services/cesium-entity.service';
import { DrawingMode } from '../../enums/drawing-mode';
import { DataSourceType } from '../../enums/datasource-type';
import { selectAllMeasures } from '../../features/measure/measure.selectors';
import { Measure } from '../../models/measure';
import { selectAllMarkers } from '../../features/marker/marker.selectors';
import { Marker } from '../../models/marker';
import { selectAllCuts } from '../../features/cut/cut.selectors';
import { Cut } from '../../models/cut';
import { InspectionModule } from '../../enums/inspection-module';
import { Actions, ofType } from '@ngrx/effects';
import { selectSitesFilters } from '../../features/config/config.selectors';
import { FiltersPipe } from '../../shared/pipes/filters.pipe';
import { SearchPipe } from '../../shared/pipes/search.pipe';
import {
  CesiumPrimitiveService
} from '../../services/cesium-primitive.service';
import { InspectionModulePipe } from '../../shared/pipes/module.pipe';
import {
  setColorRampMax,
  setColorRampMin,
  setColorRampNegativeColors,
  setColorRampPositiveColors,
  setSearchWorldText,
} from '../../features/config/config.actions';
import { SiteTools } from '../../tools/site.tools';
import { retrieveSite } from '../../features/site/site.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CreateDetectionDialog
} from '../../shared/dialogs/create-detection-dialog/create-detection.dialog';
import { VolumeColorMode } from '../../enums/volume';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'world',
  templateUrl: './world.component.html',
  styleUrls: ['./world.component.scss'],
})
export class WorldComponent implements AfterViewInit {

  @ViewChild(CesiumDirective) cesium: any;
  mapHandleKeyboard: boolean = true;
  inspectionModule = InspectionModule;
  selectedSite: Site | undefined;
  private cameraMode: CameraMode = CameraMode.MODE_3D;
  private isMiniMapDragging = false;
  private drawingMode: DrawingMode | undefined;
  private floatingPoint: any | undefined;
  private activeShapePoints: Array<any> = [];
  private activeShape: any | undefined;
  private tilesetPrimitiveCollection = new Cesium.PrimitiveCollection();
  private densityPrimitiveCollection = new Cesium.PrimitiveCollection();
  private primitiveCollection = new Cesium.PrimitiveCollection();

  constructor(private store: Store,
              private actions: Actions,
              private toastr: ToastrService,
              private overlayService: OverlayService,
              private entityService: CesiumEntityService,
              private primitiveService: CesiumPrimitiveService,
              private inspectionModulePipe: InspectionModulePipe,
              private filterPipe: FiltersPipe,
              private searchPipe: SearchPipe,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.initHandleDrawing();
    this.initHandleKeyboard();
    this.initHandleSite();
    this.initHandleMiniMap();
    this.initHandleCamera();
    this.initHandleDetections();
    this.initHandleVolumes();
    this.initHandleMeasures();
    this.initHandleMarkers();
    this.initHandleCuts();
  }

  private initHandleDrawing(): void {
    this.store.select(selectDrawingMode)
      .pipe(takeUntilDestroyed())
      .subscribe((mode: DrawingMode | undefined) => {
        this.drawingMode = mode;
        if (!this.drawingMode) {
          this.floatingPoint = undefined;
        } else {
          this.cesium.viewer.entities.removeAll();
          this.activeShape = undefined;
          this.activeShapePoints = [];
        }
      });
  }

  private initHandleKeyboard(): void {
    this.store.select(selectMapHandleKeyboard)
      .pipe(takeUntilDestroyed())
      .subscribe((mapHandleKeyboard: boolean) => {
        this.mapHandleKeyboard = mapHandleKeyboard;
      });
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          if (this.selectedSite && site.id != this.selectedSite.id) {
            this.configureSite(site);
          }
          else if (!this.selectedSite) {
            this.configureSite(site);
          }
        }else if (this.cesium) {
          this.clean();
        }
      });

    this.store.select(selectSiteDensityHighlight)
      .pipe(takeUntilDestroyed())
      .subscribe((density: DetectionDensity | VolumeDensity | undefined) => {
        if (this.cesium && this.cesium.viewer && this.cesium.viewer.scene) {
          this.densityPrimitiveCollection.removeAll();
          if (density) {
            this.primitiveService.createDensityPrimitive(
              this.densityPrimitiveCollection,
              density
            );
          }
        }
      });

    combineLatest([
      this.store.select(selectAllSites),
      this.store.select(selectSearchSiteText),
      this.store.select(selectSitesFilters)
    ]).pipe(takeUntilDestroyed())
      .subscribe((results) => {
        let sites: Array<Site> = results[0];
        const search:string = (results[1]) ? results[1] : '';
        sites = this.filterPipe.transform(sites);
        sites = this.searchPipe.transform(sites, search, true, 'name');
        if (this.cesium && sites) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.SITE);
          dataSource.entities.removeAll();
          sites.forEach(site =>  {
            this.entityService.createSiteEntity(dataSource, site);
          });
        }
      });
  }

  private initHandleMiniMap(): void {
    this.store.select(selectIsMiniMapDragging)
      .pipe(takeUntilDestroyed())
      .subscribe((isDragging: boolean) => {
        this.isMiniMapDragging = isDragging;
      });
    this.store.select(selectMiniMapCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
          this.updateCameraConfiguration(
            this.cameraMode,
            cameraConfiguration,
            this.isMiniMapDragging
          );
      });
  }

  private initHandleCamera() {
    this.store.select(selectCameraMode)
      .pipe(takeUntilDestroyed())
      .subscribe( (mode: CameraMode) => {
        this.updateCameraMode(mode);
      });
    this.store.select(selectCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
        this.updateCameraConfiguration(
          this.cameraMode,
          cameraConfiguration,
          this.isMiniMapDragging
        );
      });
    this.store.select(selectCameraRotationRate)
      .pipe(takeUntilDestroyed())
      .subscribe( (rotationRate: number | undefined) => {
        this.updateCameraLookAt(rotationRate);
      });
  }

  private initHandleDetections(): void {
    this.store.select(selectCurrentDetection)
      .pipe(takeUntilDestroyed())
      .subscribe((selectedDetection: Detection | null | undefined) => {
        if (this.primitiveCollection.length) {
          for(let i = 0 ; i < this.primitiveCollection.length ; i++) {
            const primitive = this.primitiveCollection.get(i);
            const detection = primitive.entity;
            if (selectedDetection) {
              if (detection.id == selectedDetection.id) {
                this.primitiveCollection.remove(primitive);
                this.primitiveService.createDetectionPrimitive(this.primitiveCollection, selectedDetection, true);
                break
              }
            }
            else {
              if (primitive.isSelected) {
                this.primitiveCollection.remove(primitive);
                this.primitiveService.createDetectionPrimitive(this.primitiveCollection, detection);
                break
              }
            }
          }
        }
      });

    this.actions
      .pipe(ofType(loadDetectionsSuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.store.select(selectAllDetections)
          .pipe(first())
          .subscribe((detections: Array<Detection>) => {
            if (this.cesium) {
              this.primitiveCollection.removeAll();
              if (detections) {
                detections.forEach((detection: Detection) => {
                  this.primitiveService.createDetectionPrimitive(this.primitiveCollection, detection);
                });
              }
            }
          });
      });

    this.actions
      .pipe(ofType(
        addDetectionSuccess,
        deleteDetectionSuccess,
        updateDetectionSuccess,
        addDetectionEmergencySuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(retrieveSite({
                organizationId: site.organization,
                siteId: site.id
              }));
            }
          });
      });

    this.actions
      .pipe(ofType(addDetectionEmergencyFailure), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.error(this.translocoService.translate('add_detection_emergency_failure'));
      });
    this.actions
      .pipe(ofType(updateDetectionFailure), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.error(this.translocoService.translate('update_detection_failure'));
      });

    this.actions.pipe(ofType(addDetectionEmergencySuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          this.clearDraw();
          this.toastr.success(this.translocoService.translate('add_detection_emergency_success'));
        }
      });

    this.actions
      .pipe(ofType(addDetectionSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.success(this.translocoService.translate('add_detection_success'));
        this.primitiveService.createDetectionPrimitive(this.primitiveCollection, result.detection);
        this.store.dispatch(selectDetection({id: undefined}));
        this.store.dispatch(selectDetection({id: result.detection.id}));
      });

    this.actions.pipe(ofType(addDetectionFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.warning(this.translocoService.translate('add_detection_failure'));
      });

    this.actions
      .pipe(ofType(deleteDetectionSuccess), takeUntilDestroyed())
      .subscribe(result => {
        const primitive = CesiumTools.retrievePrimitiveByEntityIdFromCollection(this.primitiveCollection, result.id);
        this.primitiveCollection.remove(primitive);
        this.store.dispatch(selectDetection({id: undefined}));
      });
    this.actions
      .pipe(ofType(deleteDetectionFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.warning(this.translocoService.translate('delete_detection_failure'));
      });
  }

  private initHandleVolumes(): void {
    this.store.select(selectAllVolumes)
      .pipe(takeUntilDestroyed())
      .subscribe((volumes ) => {
        if (this.cesium) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.VOLUME);
          dataSource.entities.removeAll();
          if (volumes) {
            volumes.forEach((volume: Volume) => {
              this.entityService.createVolumeEntity(dataSource, volume);
            });
          }
        }
      });

    this.actions
      .pipe(ofType(addVolumeEmergencySuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.success(this.translocoService.translate('add_volume_emergency_success'));
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(retrieveSite({
                organizationId: site.organization,
                siteId: site.id
              }));
            }
          });
      });

    this.actions
      .pipe(ofType(addVolumeEmergencyFailure), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.error(this.translocoService.translate('add_volume_emergency_failure'));
      });
  }

  private initHandleMeasures() {
    this.store.select(selectAllMeasures)
      .pipe(takeUntilDestroyed())
      .subscribe((measures: Array<Measure> | null | undefined) => {
        if (this.cesium) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
          dataSource.entities.removeAll();
          if (measures) {
            measures.forEach((measure: Measure) => {
              this.entityService.createMeasureEntity(dataSource, measure);
            });
          }
        }
      });

    this.actions.pipe(ofType(addMeasureSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.success(this.translocoService.translate('add_measure_success'));
        this.store.dispatch(selectMeasure({id: result.measure.id}));
      });

    this.actions.pipe(ofType(addMeasureFailure), takeUntilDestroyed())
      .subscribe(result => {
          this.clearDraw();
          this.toastr.warning(this.translocoService.translate('add_measure_failure'));
      });
  }

  private initHandleMarkers(): void {
    this.store.select(selectAllMarkers)
      .pipe(takeUntilDestroyed())
      .subscribe((markers: Array<Marker> | null | undefined) => {
        if (this.cesium) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
          dataSource.entities.removeAll();
          if (markers) {
            markers.forEach((marker: Marker) => {
              this.entityService.createMarkerEntity(dataSource, marker);
            });
          }
        }
      });

    this.actions.pipe(ofType(addMarkerSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.success(this.translocoService.translate('add_marker_success'));
        this.store.dispatch(selectMarker({id: result.marker.id}));
      });

    this.actions.pipe(ofType(addMarkerFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.warning(this.translocoService.translate('add_marker_failure'));
      });
  }

  private initHandleCuts(): void {
    this.store.select(selectAllCuts)
      .pipe(takeUntilDestroyed())
      .subscribe((cuts: Array<Cut> | null | undefined) => {
        if (this.cesium) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
          dataSource.entities.removeAll();
          if (cuts) {
            cuts.forEach((cut: Cut) => {
              this.entityService.createCutEntity(
                dataSource, cut, this.selectedSite?.altitude, this.selectedSite?.height
              );
            });
          }
        }
      });

    this.actions.pipe(ofType(addCutSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          this.clearDraw();
          this.toastr.success(this.translocoService.translate('add_cut_success'));
          this.store.dispatch(selectCut({id: result.cut.id}));
        }
      });

    this.actions.pipe(ofType(addCutFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.warning(this.translocoService.translate('add_cut_failure'));
      });
  }

  ngAfterViewInit() {
    this.configureCamera();
    this.configureInputs();
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));

    if(!this.cesium || !this.cesium.viewer) return;
    this.cesium.viewer.scene.primitives.add(this.tilesetPrimitiveCollection);
    this.cesium.viewer.scene.primitives.add(this.densityPrimitiveCollection);
    this.cesium.viewer.scene.primitives.add(this.primitiveCollection);
  }

  private configureCamera(): void {
    if(!this.cesium || !this.cesium.viewer) return;
    this.cesium.viewer.camera.changed.addEventListener((event: any)=> {
      this.store.select(selectIsMiniMapDragging)
        .pipe(first())
        .subscribe((isMiniMapDragging:boolean) => {
          const camera = this.cesium.viewer.camera;
          if (camera && this.cameraMode == CameraMode.MODE_2DV && !isMiniMapDragging) {
            const cameraConfiguration: CameraConfiguration = {
              position: camera.position.clone(),
              orientation: {
                heading: camera.heading,
                pitch: camera.pitch,
                roll: camera.roll,
              }
            };
            this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
          }
        });
    });
  }

  private configureInputs(): void {
    if(!this.cesium || !this.cesium.viewer) return;
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseMove(this.cesium.viewer, movement.endPosition); },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => {this.onViewerMouseLeftClick(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_CLICK);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseLeftDoubleClick(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseRightClick(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseLeftUp(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_UP);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseLeftUp(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_DOWN);
  }

  private onViewerMouseMove(viewer:any, position: any): void {
    if(this.drawingMode) {
      if (Cesium.defined(this.floatingPoint)) {
        const ray = viewer.camera.getPickRay(position);
        const length: any = this.tilesetPrimitiveCollection.length;
        let newPosition = (length) ? this.tilesetPrimitiveCollection.get(0).pick(ray, viewer.scene) : undefined;
        if(!newPosition) newPosition = viewer.scene.globe.pick(ray, viewer.scene);
        if (Cesium.defined(newPosition)) {
          this.floatingPoint.position.setValue(newPosition);
          // const distance = Cesium.Cartesian3.distance(this.activeShapePoints[this.activeShapePoints.length-2], newPosition);
          // this.floatingPoint.label.text = distance.toFixed(2)+ ' m';
          this.activeShapePoints.pop();
          this.activeShapePoints.push(newPosition);
        }
      }
    }else {
      const feature = this.cesium.viewer.scene.pick(position);
      this.overlayService.displayFeatureContent(viewer, this.cesium.entityOverlay, feature, position);
    }
  }

  private onViewerMouseLeftClick(viewer:any, position: any): void {
    if (this.drawingMode) {
      const ray = viewer.camera.getPickRay(position);
      const length: any = this.tilesetPrimitiveCollection.length;
      let earthPosition = (length) ? this.tilesetPrimitiveCollection.get(0).pick(ray, viewer.scene) : undefined;
      if(!earthPosition) earthPosition = viewer.scene.globe.pick(ray, viewer.scene);
      if (!earthPosition) return;
      if (Cesium.defined(earthPosition)) {
        if (this.activeShapePoints.length === 0) {
          this.floatingPoint = this.entityService.createDrawPointEntity(this.cesium.viewer, this.drawingMode, earthPosition);
          this.activeShapePoints.push(earthPosition);
          const dynamicPositions = new Cesium.CallbackProperty( () => {
            return this.activeShapePoints;
          }, false);
          const dynamicMinumunHeights = new Cesium.CallbackProperty( () => {
            return this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude : 0);
          }, false);
          const dynamicMaxumunHeights = new Cesium.CallbackProperty( () => {
            return this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude + this.selectedSite.height : 100);
          }, false);

          this.activeShape = this.entityService.drawShape(
            this.cesium.viewer, this.drawingMode, dynamicPositions, dynamicMinumunHeights, dynamicMaxumunHeights
          );
        }
        this.activeShapePoints.push(earthPosition);
        this.entityService.createDrawPointEntity(this.cesium.viewer, earthPosition, this.selectedSite?.height);
        if ((this.drawingMode == DrawingMode.MEASURE_LINE || this.drawingMode == DrawingMode.CUT_LINE)
          && this.activeShapePoints.length == 3) {
          this.stopDrawing(viewer);
        }
        else if (this.drawingMode == DrawingMode.NEW_DETECTION && this.activeShapePoints.length == 5) {
          this.activeShapePoints.push(this.activeShapePoints[0]);
          this.stopDrawing(viewer);
        }
        else if (this.drawingMode == DrawingMode.MARKER_POINT || this.drawingMode == DrawingMode.IMAGES_AT_POINT) {
          this.stopDrawing(viewer);
        }
      }
    }else {
      const feature = this.cesium.viewer.scene.pick(position);
      if (Cesium.defined(feature) &&
        (Cesium.defined(feature.id) || Cesium.defined(feature.primitive.entity))) {
        const entity = feature.id || feature.primitive.entity;
        this.entityService.displayEntity(entity);
      }
    }
  }

  private onViewerMouseLeftDoubleClick(viewer:any, position: any): void {

  }

  private onViewerMouseRightClick(viewer:any, position: any): void {
    if (this.drawingMode) {
      this.activeShapePoints.pop();
      if(this.activeShapePoints.length >= 3) {
        if(this.drawingMode == DrawingMode.MEASURE_POLYGON) {
          this.activeShapePoints.push(this.activeShapePoints[0]);
          this.entityService.drawShape(
            viewer,
            this.drawingMode,
            this.activeShapePoints,
            this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude : 0),
            this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude + this.selectedSite.height : 0)
          );
        }
        viewer.entities.remove(this.floatingPoint);
        this.stopDrawing(viewer);
      }
      else {
        this.clearDraw();
      }
    }
  }

  private onViewerMouseLeftUp(viewer:any, position: any): void {

  }

  private onViewerMouseLeftDown(viewer:any, position: any): void {

  }

  private updateCameraMode(mode: any): void {
    if (this.cameraMode != mode) {
      this.cameraMode = mode;
      this.cesium.setMode(this.cameraMode);
    }
  }

  private updateCameraConfiguration(mode: any, cameraConfiguration: CameraConfiguration, isDragging: boolean): void {
    switch (mode){
      case CameraMode.MODE_2DV: {
        if (cameraConfiguration) {
          if (isDragging) {
            const camera = this.cesium.viewer.camera;
            camera.position = Cesium.Cartesian3.fromElements(cameraConfiguration.position.x, cameraConfiguration.position.y, cameraConfiguration.position.z);
          }
        }
        break;
      }
      default: {
        if (cameraConfiguration){
          const preset = CesiumTools.cameraFromCameraConfiguration(cameraConfiguration);
          this.cesium.viewer.camera.flyTo(preset);
        }
        break;
      }
    }
  }

  private updateCameraLookAt(rotationRate: any): void {
    switch (this.cameraMode){
      case CameraMode.MODE_2DV: {
        if(rotationRate) {
          const camera = this.cesium.viewer.camera;
          camera.lookRight(rotationRate);
          const heading = Math.round((camera.heading || 0) * 180 / Cesium.Math.PI);
          const orientation = CesiumTools.cameraHeadingPitchRoll(
            heading,
            0,
            0
          );
          const cameraConfiguration: CameraConfiguration = {position: camera.position.clone(), orientation};
          this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private stopDrawing(viewer: any): void {
    if (this.selectedSite?.organization && this.selectedSite?.id) {
      switch (this.drawingMode) {
        case DrawingMode.MEASURE_LINE:
          this.store.dispatch(addMeasureDistance({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            point1: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            point2: CesiumTools.cartesian3ToArray(this.activeShapePoints[this.activeShapePoints.length -1]),
            cameraPosition: CesiumTools.cartesian3ToArray(viewer.camera.position),
          }));
          break;
        case DrawingMode.MEASURE_POLYGON:
          this.store.dispatch(addMeasureArea({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            polygon: CesiumTools.cartesian3ArrayToArray(this.activeShapePoints.slice(0, this.activeShapePoints.length-1)),
            cameraPosition: CesiumTools.cartesian3ToArray(viewer.camera.position)
          }));
          break;
        case DrawingMode.CUT_LINE:
          this.store.dispatch(addCut({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            point1: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            point2: CesiumTools.cartesian3ToArray(this.activeShapePoints[this.activeShapePoints.length -1]),
          }));
          break;
        case DrawingMode.MARKER_POINT:
          this.store.dispatch(addMarker({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            point: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            cameraPosition: CesiumTools.cartesian3ToArray(viewer.camera.position)
          }));
          break;
        case DrawingMode.NEW_DETECTION:
          this.displayNewDetectionDialog(
            this.selectedSite,
            CesiumTools.cartesian3ArrayToArray(this.activeShapePoints.slice(0,4)),
            CesiumTools.cartesian3ToArray(viewer.camera.position)
          );
          break;
        default:
          break;
      }
    }
    this.store.dispatch(setDrawingMode({drawingMode: undefined}));
  }

  private clearDraw(): void {
    this.floatingPoint = undefined;
    this.cesium.viewer.entities.removeAll();
    this.activeShape = undefined;
    this.activeShapePoints = [];
    this.store.dispatch(setDrawingMode({drawingMode: undefined}));
  }

  private configureSite(site: Site) {
    this.selectedSite = site;
    const tilesUrl = SiteTools.tilesUrl(site);
    this.tilesetPrimitiveCollection.removeAll();
    this.cesium.displayDataSources(false);

    const position = Cesium.Cartesian3.fromArray(site.pin);
    const preset = CesiumTools.camera3DPresetsFromPosition(position);
    this.cesium.viewer.camera.flyTo(preset);

    this.cesium.viewer.scene.globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(
      50.0,
      0.0,
      1500.0,
      1.0
    );

    if (tilesUrl) {
      Cesium.Cesium3DTileset.fromUrl(tilesUrl, {
        skipLevelOfDetail: true,
        baseScreenSpaceError: 1024,
        skipScreenSpaceErrorFactor: 16,
        skipLevels: 1,
        immediatelyLoadDesiredLevelOfDetail: false,
        loadSiblings: false,
        cullWithChildrenBounds: true
      }).then((tileset:any) => {
          this.tilesetPrimitiveCollection.add(tileset);
      }).catch((err:any) => console.log(tilesUrl, err));
    }

    if (this.inspectionModulePipe.transform(site, [InspectionModule.ARCAD])) {
      this.store.dispatch(loadDetections({
        organizationId: site.organization,
        siteId: site.id,
        batchId: site.lastBatch
      }));
      this.store.dispatch(loadDetectionTemplate({
        organizationId: site.organization,
        siteId: site.id
      }));
      this.store.dispatch(loadDetectionEmergencyTemplate({
        organizationId: site.organization,
        siteId: site.id
      }));
    }

    if (this.inspectionModulePipe.transform(site, [InspectionModule.PERREAD])) {
      this.store.dispatch(loadVolumes({
        organizationId: site.organization,
        siteId: site.id,
        batchId: site.lastBatch,
        threshold: 1,
        step: 1
      }));
      this.store.dispatch(loadVolumeEmergencyTemplate({
        organizationId: site.organization,
        siteId: site.id
      }));
    }
  }

  private clean(): void {
    this.selectedSite = undefined;
    this.cesium.viewer.entities.removeAll();
    this.densityPrimitiveCollection.removeAll();
    this.tilesetPrimitiveCollection.removeAll();
    this.primitiveCollection.removeAll();
    this.cesium.displayDataSources(true);
    this.cesium.viewer.scene.globe.translucency.frontFaceAlphaByDistance = undefined;
    this.store.dispatch(setVolumeColorMode({colorMode: VolumeColorMode.COLOR_MODE_VOLUME_MOVED}));
    this.store.dispatch(setDrawingMode({drawingMode: undefined}));
    this.store.dispatch(setColorRampMin({value: 0}));
    this.store.dispatch(setColorRampMax({value: 0}));
    this.store.dispatch(setColorRampNegativeColors({colors: []}));
    this.store.dispatch(setColorRampPositiveColors({colors: []}));
    this.store.dispatch(setMiniMapCameraConfiguration({cameraConfiguration: undefined}))
    this.store.dispatch(clearAllMeasures());
    this.store.dispatch(clearAllDetections());
    this.store.dispatch(clearAllVolumes());
    this.store.dispatch(clearAllCuts());
    this.store.dispatch(clearAllMarkers());
    this.store.dispatch(clearVolumeEmergencyTemplate());
    this.store.dispatch(clearDetectionEmergencyTemplate());
    this.store.dispatch(clearDetectionTemplate());
    this.store.dispatch(setSearchWorldText({text: undefined}));
    this.store.dispatch(selectDetection({id: undefined}));
    this.store.dispatch(selectVolume({id: undefined}));
    this.store.dispatch(selectMeasure({id: undefined}));
    this.store.dispatch(selectMarker({id: undefined}));
    this.store.dispatch(selectCut({id: undefined}));
  }

  private displayNewDetectionDialog(site: Site, points: Array<Array<number>>, cameraPosition: Array<number>): void {
    const dialogRef: MatDialogRef<CreateDetectionDialog> = this.dialog.open(CreateDetectionDialog, {
      disableClose: false
    });
    dialogRef.afterClosed()
      .subscribe(result => {
      if(result) {
        this.store.dispatch(addDetection({
          organizationId: site.organization,
          siteId: site.id,
          batchId: site.lastBatch,
          payload: {
            'label': result,
            'points': points,
            'camera_position' : cameraPosition,
            'max_dist_to_plane': site.maxDistToPlane
          }}));
      }
      else {
        this.clearDraw();
      }
    });
  }

}
