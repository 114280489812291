<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>capture</mat-icon>&nbsp;{{'new_detection' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{message}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <div>
      <mat-form-field *ngIf="detectionTemplate">
        <mat-label>{{'type' | transloco}}</mat-label>
        <mat-select required [(value)]="label">
          <mat-option *ngFor="let item of detectionTemplate.labels" [value]="item.label">{{item.label | transloco}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            [disabled]="!label"
            (click)="dialogRef.close(label)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
