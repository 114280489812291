import { createAction, props } from '@ngrx/store';

export const setConfigSideMode = createAction('[Config] Set Side Mode', props<{ sideMode: any}>());
export const setDisplaySitesFilters = createAction('[Config] Set Display Sites Filters', props<{ display: boolean}>());
export const setSitesFilters = createAction('[Config] Set Sites Filters', props<{ sitesFilters: any}>());
export const setColorRampPositiveColors = createAction('[Config] Set Color Ramp Positive Colors', props<{ colors: Array<string> }>());
export const setColorRampNegativeColors = createAction('[Config] Set Color Ramp Negative Colors', props<{ colors: Array<string> }>());
export const setColorRampMin = createAction('[Config] Set Color Ramp Min ', props<{ value: number }>());
export const setColorRampMax = createAction('[Config] Set Color Ramp Max ', props<{ value: number }>());
export const setSiteDetailSelectedSection = createAction('[Config] Set Site Detail Selected Seciton', props<{ value: number | undefined }>());
export const setSearchWorldText = createAction('[Config] Search World Text', props<{ text: string | undefined}>());
