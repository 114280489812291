import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'pictures-viewer-dialog',
  templateUrl: './pictures-viewer.dialog.html',
  styleUrls: ['../dialog.scss','./pictures-viewer.dialog.scss']
})
export class PicturesViewerDialog {

  title: string = '';
  subtitle: string = '';
  images: Array<any> = [];

  constructor(public dialogRef: MatDialogRef<PicturesViewerDialog>) {}

  onClickLeft(): void {

  }
  onClickRight(): void {

  }
}
