import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input, OnChanges, SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addDetectionComment,
  addDetectionCommentFailure,
  addDetectionCommentSuccess,
  addDetectionEmergencySuccess,
  loadDetectionComments,
  loadDetectionCommentsSuccess
} from '../../../features/detection/detection.actions';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DetectionComment } from '../../../models/detection-comment';
import { setMapHandleKeyboard } from '../../../features/cesium/cesium.actions';
import { ToastrService } from 'ngx-toastr';
import {
  addVolumeEmergencySuccess
} from '../../../features/volume/volume.actions';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'history-channel',
  templateUrl: './history-channel.component.html',
  styleUrls: ['./history-channel.component.scss'],
})
export class HistoryChannelComponents implements OnChanges {

  @Input({required: true}) detectionId: string | undefined;
  comments: Array<DetectionComment> = [];
  @ViewChild('editor') textareaRef: ElementRef<HTMLTextAreaElement> | undefined;
  @ViewChild('commentList') commentsRef: ElementRef<HTMLDivElement> | undefined;

  constructor(private store: Store,
              private actions: Actions,
              private toastr: ToastrService,
              private translocoService: TranslocoService) {
    this.initHandleComments();
  }

  private initHandleComments(): void {
    this.actions.pipe(ofType(addDetectionEmergencySuccess, addVolumeEmergencySuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.loadComments();
      });
    this.actions.pipe(ofType(loadDetectionCommentsSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.comments = Object.assign([], result.comments);
        this.scrollToBottom();
      });
    this.actions.pipe(ofType(addDetectionCommentSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.comments.push(result.comment);
        this.scrollToBottom();
        this.clearComment();
      });
    this.actions.pipe(ofType(addDetectionCommentFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate("add_detection_comment_failure"));
      });
  }

  private loadComments() {
    if (this.detectionId) {
      this.store.dispatch(loadDetectionComments({detectionId: this.detectionId}));
    }
  }

  private clearComments() {
    this.comments = [];
  }

  private clearComment() {
    if (this.textareaRef) {
      this.textareaRef.nativeElement.value = '';
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.commentsRef) {
        this.commentsRef.nativeElement.scrollTop = this.commentsRef.nativeElement.scrollHeight;
      }
    }, 0);
  }

  dateToTimestamp(date: string): number {
    return new Date(date).getTime();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearComments();
    this.loadComments();
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickAddComment(): void {
    if (this.detectionId && this.textareaRef) {
      this.store.dispatch(addDetectionComment({
        detectionId: this.detectionId,
        payload: {'text': this.textareaRef.nativeElement.value}
      }))
    }
  }
}
