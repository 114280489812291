export enum SiteStatus {
  NOT_AVAILABLE = 0,
  PENDING,
  INITIALIZING,
  UPDATING,
  RUNNING,
  ERROR,
}

export enum SiteBackgroundColor {
  AVAILABLE = 'rgba(0, 124, 166, 0.2)',
  NOT_AVAILABLE = '#efefef',
}

export enum SiteBorderColor {
  AVAILABLE = '0.1px solid #007CA6',
  NOT_AVAILABLE = '0.1px solid rgba(0, 0, 0, 0.1)',
}

export enum SiteTextColor {
  AVAILABLE = '#007CA6',
  NOT_AVAILABLE = 'rgba(0, 0, 0, 0.3)',
}
