import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  setCameraConfiguration,
  setCameraMode,
} from '../../../../features/cesium/cesium.actions';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { Volume } from '../../../../models/volume';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  selectVolumeColorMode
} from '../../../../features/volume/volume.selectors';
import { selectVolume } from '../../../../features/volume/volume.actions';
import { VolumeColorMode } from '../../../../enums/volume';

@Component({
  selector: 'volume-card',
  templateUrl: './volume-card.component.html',
})
export class VolumeCardComponent {

  @Input({required: true}) volume: Volume | undefined;
  private colorMode: VolumeColorMode | undefined;

  constructor(private store: Store) {
    this.initHandleVolumeColorMode();
  }

  private initHandleVolumeColorMode() {
    this.store.select(selectVolumeColorMode)
      .pipe(takeUntilDestroyed())
      .subscribe((colorMode: any) => {
        this.colorMode = colorMode;
      });
  }

  onClickFlyTo(volume: Volume): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(volume.center, volume.cameraDirection, volume.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickEdit(volume: Volume): void {
    this.store.dispatch(selectVolume({id: volume.id}));
  }
}
