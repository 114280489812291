import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Measure } from '../../../../models/measure';
import {
  selectAllMeasures
} from '../../../../features/measure/measure.selectors';
import { Observable } from 'rxjs';


@Component({
  selector: 'measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class MeasureListComponent {

  constructor(private store: Store) {  }

  get measures$(): Observable<Array<Measure>> {
    return this.store.select(selectAllMeasures);
  }
}
