import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as UserActions from './user.actions';
import { ApiService } from '../../services/api.service';
import { Deserialize } from 'cerialize';
import { User } from '../../models/user';


@Injectable()
export class UserEffects {

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUser),
      concatMap(() =>
        from(this.api.me$()).pipe(
          map(user => Deserialize(user, User)),
          map(user => UserActions.getUserSuccess({ user })),
          catchError(error => of(UserActions.getUserFailure({ error }))))
      )
    );
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
