import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { User } from '../../models/user';

export interface State {
  user: User | undefined;
}

export const initialState: State = {
  user: undefined,
};


const userReducer = createReducer(
  initialState,
  on(UserActions.getUser, (state) =>  state),
  on(UserActions.getUserSuccess, (state, { user }) => {
    return { ...state, user };
  }),
  on(UserActions.getUserFailure, (state, { error }) => {
    return state;
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return userReducer(state, action);
}
