import { createAction, props } from '@ngrx/store';
import { Measure } from '../../models/measure';
import { Update } from '@ngrx/entity';

export const addMeasureDistance = createAction('[Measure] Add Measure Distance', props<{ organizationId: string, siteId: string, point1: Array<number>, point2: Array<number>, cameraPosition: Array<number> }>());
export const addMeasureArea = createAction('[Measure] Add Measure Area', props<{ organizationId: string, siteId: string, polygon: Array<Array<number>>, cameraPosition: Array<number> }>());
export const addMeasureSuccess = createAction('[Measure/API] Add Measure success', props<{ measure: Measure}>());
export const addMeasureFailure = createAction('[Measure/API] Add Measure failure', props<{ error: any }>());
export const updateMeasure = createAction('[Measure] Update Measure ', props<{ update: Update<any> }>());
export const deleteMeasure = createAction('[Measure] Delete Measure', props<{ id: string }>());
export const clearAllMeasures = createAction('[Measure] Clear All Measures');
export const selectMeasure = createAction('[Measure] Select Measure', props<{ id: string | undefined}>());
