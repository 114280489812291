<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>{{icon}}</mat-icon>&nbsp;{{title}}
    </mat-card-title>
    <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div>{{message}}</div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(true)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
