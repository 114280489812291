import { Component, ElementRef, ViewChild } from '@angular/core';
import { Cut, CutProfile } from '../../../../models/cut';
import { Store } from '@ngrx/store';
import {
  deleteCut, exportCutDXF, exportCutDXFSuccess,
  selectCut,
  updateCut
} from '../../../../features/cut/cut.actions';
import { selectCurrentCut } from '../../../../features/cut/cut.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectMeasure } from '../../../../features/measure/measure.actions';
import { selectVolume } from '../../../../features/volume/volume.actions';
import {
  selectMarker,
} from '../../../../features/marker/marker.actions';
import {
  loadDetectionsSuccess,
  selectDetection
} from '../../../../features/detection/detection.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { CutChartComponent } from '../../charts/cut-chart.component';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { TranslocoService } from '@jsverse/transloco';
import { combineLatest, first } from 'rxjs';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectAllDetections
} from '../../../../features/detection/detection.selectors';
import { Detection } from '../../../../models/detection';
import { Site } from '../../../../models/site';
import { Serialize } from 'cerialize';


@Component({
  selector: 'cut-details',
  templateUrl: './cut-details.component.html',
  styleUrls: ['../details.component.scss', './cut-details.component.scss'],
})
export class CutDetailsComponents {

  cut: Cut | null | undefined;
  @ViewChild('graph') graph: CutChartComponent | undefined;
  @ViewChild('comment') textareaRef: ElementRef<HTMLTextAreaElement> | undefined;
  private deleteDialogRef: MatDialogRef<DeleteConfirmDialog> | undefined;
  private renameDialogRef: MatDialogRef<RenameDialog> | undefined;
  private slope:Array<Array<number>> | undefined;

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.store.select(selectCurrentCut)
      .pipe(takeUntilDestroyed())
      .subscribe((cut: Cut | null | undefined) => {
        this.cut = cut;
        if (this.cut) {
          this.slope = (this.cut.slopeModified) ? this.cut.slopeModified : this.cut.slope;
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectMarker({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
        }
      });
    this.actions
      .pipe(ofType(exportCutDXFSuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        if (this.cut) {
          ExportTools.exportDXF(this.cut.name, data['dxf']);
        }
      });
  }

  get horizontalDistance(): number | undefined {
    if (this.slope) {
      const x1 = this.slope[0][0];
      const x2 = this.slope[this.slope.length-1][0];
      return Math.abs(x1 - x2);
    }
    return undefined
  }

  get verticalDistance(): number | undefined {
    if (this.slope) {
      const y1 = this.slope[0][1];
      const y2 = this.slope[this.slope.length - 1][1];
      return Math.abs(y1 - y2);
    }
    return undefined;
  }

  get angle(): number | undefined {
    if (this.verticalDistance && this.horizontalDistance){
      return Math.atan2(this.verticalDistance, this.horizontalDistance) * 180.0 / Math.PI;
    }
    return undefined;
  }

  private save(): void {
    if (this.cut && this.textareaRef){
      this.store.dispatch(updateCut({update: {id: this.cut.id, changes: {
        comment: this.textareaRef.nativeElement.value, slopeModified: this.slope
      }}}));
    }
  }

  onSlopeDragging(slope: Array<Array<number>> | undefined): void {
    this.slope = slope;
  }

  onSlopeHasChanged(slope: Array<Array<number>> | undefined): void {
    this.slope = slope;
    this.save();
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.save();
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickFlyTo(cut: Cut): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(cut.center, cut.cameraDirection, cut.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.save();
    this.store.dispatch(selectCut({id: undefined}));
  }

  onClickDelete(cut: Cut): void {
    this.deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    this.deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: cut.name});
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deleteCut({id: cut.id}));
      }
    });
  }

  onClickExportGraph(cut: Cut): void {
    if (this.graph) {
      this.graph.canvasToImage(cut.name);
    }
  }

  onClickExportData(cut: Cut): void {
    ExportTools.exportJSON(cut.name, JSON.stringify(cut));
  }

  onClickExportDXF(cut: Cut): void {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectUser)
    ]).pipe(first())
      .subscribe(contents => {
        const site: any = contents[0];
        const user = contents[1];
        if (site && user && this.cut && this.slope) {
          const cuts:Array<any> = this.cut?.profiles.map((profil: CutProfile) => Serialize(profil));
          this.store.dispatch(exportCutDXF({
            organizationId: site.organization,
            siteId: site.id,
            cuts,
            slope: this.slope,
            language_code: user.languageCode
          }));
        }
      });
  }

  onClickRename(cut: Cut): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    this.renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    this.renameDialogRef.componentInstance.placeholder = cut.name;
    this.renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.cut) {
        this.store.dispatch(updateCut({ update: {id: cut.id, changes: {name: result}}}));
      }
    });
  }
}
