<div class="container">
  <div class="title">{{'color_of_volumes_by' | transloco}} :</div>
  <mat-button-toggle-group #colorMode="matButtonToggleGroup"
                           [hideSingleSelectionIndicator]="true"
                           (change)="onModeChanged($event.value)"
                           value="2">
    <mat-button-toggle value="2">
      {{'volume_moved' | transloco}}
    </mat-button-toggle>
    <mat-button-toggle value="1">
      {{'emergency' | transloco}}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

