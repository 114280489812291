import { Pipe, PipeTransform } from '@angular/core';
import { DetectionMode, DetectionStatus } from '../../enums/detection';
import { TranslocoService } from '@jsverse/transloco';


@Pipe({name: 'formatDetectionMode'})
export class FormatDetectionModePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {
  }

  transform(detectionMode: number): string {
    if(detectionMode == DetectionMode.AUTO) {return this.translocoService.translate('automatic_detection');}
    return this.translocoService.translate('manual_detection');
  }
}

@Pipe({name: 'formatDetectionStatus'})
export class FormatDetectionStatusPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {
  }

  transform(detectionStatus: number): string {
    if(detectionStatus == DetectionStatus.TO_BE_CONFIRMED) {return this.translocoService.translate('to_confirm')}
    if(detectionStatus == DetectionStatus.CONFIRMED) {return this.translocoService.translate('confirmed');}
    return 'Inconnu';
  }
}
