import { Pipe, PipeTransform } from '@angular/core';
import { MarkerImage } from '../../models/marker';


@Pipe({name: 'sortdistance'})
export class MarkerSortDistancePipe implements PipeTransform {
  transform(data: Array<MarkerImage>,): Array<MarkerImage> {
    let sortArray = Object.assign([], data);
    return sortArray.sort((a: MarkerImage, b: MarkerImage) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      return 0;
    });
  }
}
