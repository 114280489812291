<mat-card class="container" *ngIf="marker">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{marker.name}}</mat-card-title>
      <mat-card-subtitle>{{marker.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div class="comment">
      <textarea placeholder="{{'report_note' | transloco}}"
                #comment
                (focus)="onHandleFocus()"
                (focusout)="onFocusLeave()">{{marker.comment}}</textarea>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="left-actions">
      <button mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              class="material-symbols-outlined delete-btn"
              (click)="onClickDelete(marker)">delete
      </button>
      <div class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'export' | transloco}}"
              class="material-symbols-outlined"
              [matMenuTriggerFor]="menu">ios_share
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickExportPicture(marker)">{{'export_picture' | transloco}}</button>
<!--        <button mat-menu-item (click)="onClickExportData(marker)">{{'export_data' | transloco}}</button>-->
      </mat-menu>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_maker' | transloco}}"
              class="material-symbols-outlined"
              (click)="onClickFlyTo(marker)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(marker)"><mat-icon>edit</mat-icon></button>
    </div>
  </mat-card-actions>
</mat-card>
