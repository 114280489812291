import {
  autoserialize,
  autoserializeAs, deserialize, deserializeAs,
  serialize,
  serializeAs
} from 'cerialize';
import {v4 as uuidv4} from 'uuid';

export class CutProfile {
  @autoserialize batch!: string;
  @autoserializeAs("x_values") xValues!: Array<number>;
  @autoserializeAs("y_values") yValues!: Array<number>;

  get dataset(): Array<any> {
    return this.xValues
      .map((value: number, index: number) => {
        return [value, this.yValues[index]];
      });
  }
}

export class Cut {
  id: string;
  show: boolean;
  @serialize name: string;
  @serialize comment: string;
  @serializeAs('slope') slopeModified!: Array<Array<number>>;
  @autoserialize point1!: Array<number>;
  @autoserialize point2!: Array<number>;
  @autoserializeAs('max_difference') maxDifference!: number;
  @autoserializeAs(CutProfile) profiles!: Array<CutProfile>;
  @deserialize slope!: Array<Array<number>>;
  @deserialize parameters!: any;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;

  constructor() {
    this.id = uuidv4();
    this.name = '';
    this.comment = '';
    this.show = true;
  }

  public static OnDeserialized(instance : Cut, json : any) : void {
    instance.slopeModified = instance.slope;
  }
}

