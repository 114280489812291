<mat-card>

  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>
        <mat-icon>photo_library</mat-icon>&nbsp;{{title}}
      </mat-card-title>
      <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              [autofocus]="false"
              (click)="dialogRef.close()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>

  <mat-card-content>
    <div class="carousel" #carousel>
      <picture [url]="image.url" *ngFor="let image of images; index as i;"></picture>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-icon-button (click)="onClickLeft()"><mat-icon>chevron_left</mat-icon></button>
    <button mat-icon-button (click)="onClickRight()"><mat-icon>chevron_right</mat-icon></button>
  </mat-card-actions>

</mat-card>
