import { createAction, props } from '@ngrx/store';
import {
  DetectionDensity,
  Site,
  VolumeDensity
} from '../../models/site';
import { Update } from '@ngrx/entity';

export const setSiteDensityHighlight = createAction('[Site] Site Density Highlight', props<{ density: DetectionDensity | VolumeDensity | undefined}>());
export const setSearchSiteText = createAction('[Site] Search Site', props<{ text: string | undefined}>());
export const selectSite = createAction('[Site] Select Site', props<{ siteId: string | undefined}>());

export const loadSites = createAction('[Site/API] Load Sites ', props<{ organizationId: string }>());
export const loadSitesSuccess = createAction('[Site/API] Load Sites success', props<{ sites: Site[] }>());
export const loadSitesFailure = createAction('[Site/API] Load Sites failure', props<{ error: any }>());

export const retrieveSite = createAction('[Site/API] Retrieve Site ', props<{ organizationId: string, siteId: string}>());
export const retrieveSiteSuccess = createAction('[Site/API] Retrieve Site Success', props<{ update: Update<any> }>());
export const retrieveSiteFailure = createAction('[Site/API] Retrieve Site failure', props<{ error: any }>());

export const activateSite = createAction('[Site/API] Activate Site ', props<{ organizationId: string, siteId: string}>());
export const activateSiteSuccess = createAction('[Site/API] Activate Site Success', props<{ update: Update<any> }>());
export const activateSiteFailure = createAction('[Site/API] Activate Site failure', props<{ error: any }>());

export const deactivateSite = createAction('[Site/API] Deactivate Site ', props<{ organizationId: string, siteId: string}>());
export const deactivateSiteSuccess = createAction('[Site/API] Deactivate Site Success', props<{ update: Update<any> }>());
export const deactivateSiteFailure = createAction('[Site/API] Deactivate Site failure', props<{ error: any }>());
