import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'report-dialog',
  templateUrl: './report.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class ReportDialog {
  constructor(public dialogRef: MatDialogRef<ReportDialog>) {}

  availableFormats: Array<any> = [
    {name: 'PDF', extension: 'pdf', checked: false},
    {name: 'Docx', extension: 'docx', checked: false},
    {name: 'LibreOffice/OpenOffice', extension: 'odt', checked: false}
  ];


  get isValid(): boolean {
    return this.availableFormats.map(e => e.checked).filter(b => b).length > 0;
  }

  get selectedFormats(): Array<string> {
    return this.availableFormats.filter(b => b.checked).map(e => e.extension);
  }

}
