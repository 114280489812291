import { createAction, props } from '@ngrx/store';
import { Marker } from '../../models/marker';
import { Update } from '@ngrx/entity';

export const addMarker = createAction('[Marker] Add Marker ', props<{ organizationId: string, siteId: string, point: Array<number>, cameraPosition: Array<number> }>());
export const addMarkerSuccess = createAction('[Marker/API] Add Marker success', props<{ marker: Marker}>());
export const addMarkerFailure = createAction('[Marker/API] Add Marker failure', props<{ error: any }>());
export const updateMarker = createAction('[Marker] Update Marker ', props<{ update: Update<Marker> }>());
export const deleteMarker = createAction('[Marker] Delete Marker', props<{ id: string }>());
export const clearAllMarkers = createAction('[Marker] Clear All Markers');
export const selectMarker= createAction('[Marker] Select Marker', props<{ id: string | undefined}>());
