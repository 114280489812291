import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromUser from './user.reducer';


export const selectUserState = createFeatureSelector<fromUser.State>('user');

export const selectUser = createSelector(
  selectUserState,
  state => state.user
);

