import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  setDisplaySitesFilters, setSitesFilters,
} from '../../../features/config/config.actions';
import { Sector } from '../../../models/sector';
import { selectAllSectors } from '../../../features/sector/sector.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectSitesFilters } from '../../../features/config/config.selectors';
import {
  SiteFilterDetectionKeys,
  SiteFilterKeys, SiteFilterVolumeKeys
} from '../../../enums/site-filter';
import { selectAllSites } from '../../../features/site/site.selectors';
import { Site } from '../../../models/site';
import { FilterSlide } from '../../../models/filter-slide';
import { combineLatest } from 'rxjs';



@Component({
  selector: 'site-filters',
  templateUrl: './site-filters.component.html',
  styleUrls: ['./site-filters.component.scss']
})
export class SiteFiltersComponent {

  siteFilterKeysEnum = SiteFilterKeys
  config: any;
  sectors: Array<Sector> | undefined;
  emergencies: Array<any> = []

  detectionHighEmergencyLevelFilterSlide: FilterSlide = new FilterSlide();
  detectionTotalFilterSlide: FilterSlide = new FilterSlide();
  detectionEvolutionFilterSlide: FilterSlide = new FilterSlide()
  detectionDensityPeakFilterSlide: FilterSlide = new FilterSlide();

  volumeHighEmergencyLevelFilterSlide: FilterSlide = new FilterSlide();
  volumePositiveVolumeMovedFilterSlide: FilterSlide = new FilterSlide();
  volumeNegativeVolumeMovedFilterSlide: FilterSlide = new FilterSlide();
  volumeDensityPeakFilterSlide: FilterSlide = new FilterSlide();

  private sites: Array<Site> = [];

  constructor(private store: Store) {
    this.initHandleConfigAndSites();
    this.initHandleSectors();
  }

  private initHandleConfigAndSites(): void {
    combineLatest([
      this.store.select(selectSitesFilters),
      this.store.select(selectAllSites)
    ]).pipe(takeUntilDestroyed())
      .subscribe( results => {
        this.config = (results[0]) ? JSON.parse(JSON.stringify(results[0])) : {};
        this.sites = (results[1]) ? results[1] : [];
        this.emergencies = [];
        const unique = [...new Map(this.sites.map(item =>
          [item['emergencyLabel'], item])).values()];
        unique
          .sort((a, b) => a.emergencyLevel < b.emergencyLevel ? -1 : a.emergencyLevel > b.emergencyLevel ? 1 : 0)
          .forEach((site: Site) => {
            if (site.emergencyLabel) {
              this.emergencies.push({value: site.emergencyLabel, label: site.emergencyLabel});
            }
          });
        this.setup(this.config, this.sites);
      });
  }

  private initHandleSectors(): void {
    this.store.select(selectAllSectors)
      .pipe(takeUntilDestroyed())
      .subscribe( sectors => {
        this.sectors = sectors;
      });
  }

  private setup(config: any, sites: Array<Site>): void {
    this.setupFilterSlide(this.detectionHighEmergencyLevelFilterSlide, config, SiteFilterDetectionKeys.HIGH_EMERGENCY_LEVEL,  sites, 'detectionSummary', 'emergencyCount');
    this.setupFilterSlide(this.detectionTotalFilterSlide, config, SiteFilterDetectionKeys.TOTAL, sites, 'detectionSummary', 'detectionCount');
    this.setupFilterSlide(this.detectionEvolutionFilterSlide, config, SiteFilterDetectionKeys.EVOLUTION, sites, 'detectionSummary', 'evolution');
    this.setupFilterSlide(this.detectionDensityPeakFilterSlide,  config, SiteFilterDetectionKeys.DENSITY_PEAK, sites, 'detectionSummary', 'densityPeak');

    this.setupFilterSlide(this.volumeHighEmergencyLevelFilterSlide, config, SiteFilterVolumeKeys.HIGH_EMERGENCY_LEVEL, sites, 'volumeSummary', 'emergencyCount');
    this.setupFilterSlide(this.volumePositiveVolumeMovedFilterSlide, config, SiteFilterVolumeKeys.POSITIVE_VOLUME_MOVED, sites, 'volumeSummary', 'positiveVolumeMoved');
    this.setupFilterSlide(this.volumeNegativeVolumeMovedFilterSlide, config, SiteFilterVolumeKeys.NEGATIVE_VOLUME_MOVED, sites, 'volumeSummary', 'negativeVolumeMoved');
    this.setupFilterSlide(this.volumeDensityPeakFilterSlide, config, SiteFilterVolumeKeys.DENSITY_PEAK, sites, 'volumeSummary', 'densityPeak');
  }

  private setupFilterSlide(filterSlide: FilterSlide, config: any, slideKey: string, sites: Array<any>, module: string, key: string): void {
    filterSlide.max = this.max(sites, module, key);
    const section = (module == 'detectionSummary') ? SiteFilterKeys.DETECTIONS : SiteFilterKeys.VOLUMES;
    if (!config[section] || !config[section][slideKey]) {
      filterSlide.start = 0;
      filterSlide.end = filterSlide.max;
    }
    else {
      filterSlide.start = config[section][slideKey].start;
      filterSlide.end = config[section][slideKey].end;
    }
  }

  private max(sites: Array<any>, module: string, key: string): number {
    return sites.map(site => (site[module]) ? site[module][key] : 0).reduce((prev, current) => {
      return (prev && prev > current) ? prev : current
    });
  }

  private updateDetectionFilters(config: any): any {
    if (!this.config) this.config = {};
    const detections: any = {};
    detections[SiteFilterDetectionKeys.HIGH_EMERGENCY_LEVEL] = this.detectionHighEmergencyLevelFilterSlide.toJson();
    detections[SiteFilterDetectionKeys.TOTAL] = this.detectionTotalFilterSlide.toJson();
    detections[SiteFilterDetectionKeys.EVOLUTION] = this.detectionEvolutionFilterSlide.toJson();
    detections[SiteFilterDetectionKeys.DENSITY_PEAK] = this.detectionDensityPeakFilterSlide.toJson();
    this.config[SiteFilterKeys.DETECTIONS] = detections;
    return config;
  }

  private updateVolumeFilters(config: any): any {
    if (!this.config) this.config = {};
    const volumes: any = {};
    volumes[SiteFilterVolumeKeys.HIGH_EMERGENCY_LEVEL] = this.volumeHighEmergencyLevelFilterSlide.toJson();
    volumes[SiteFilterVolumeKeys.POSITIVE_VOLUME_MOVED] = this.volumePositiveVolumeMovedFilterSlide.toJson();
    volumes[SiteFilterVolumeKeys.NEGATIVE_VOLUME_MOVED] = this.volumeNegativeVolumeMovedFilterSlide.toJson();
    volumes[SiteFilterVolumeKeys.DENSITY_PEAK] = this.volumeDensityPeakFilterSlide.toJson();
    this.config[SiteFilterKeys.VOLUMES] = volumes;
    return config;
  }

  isSelected(value: string, key: string): boolean {
    if (this.config && this.config[key]) {
      return this.config[key].filter((item:Array<any>) => {
        return item.includes(value);
      }).length > 0;
    }
    return false;
  }

  updateConfig(value: string, key: string): void {
    const isAlreadySelected: boolean = this.isSelected(value, key);
    if (isAlreadySelected){
      if (this.config && this.config[key]) {
        const index = this.config[key].indexOf(value, 0);
        if (index > -1) {
          this.config[key].splice(index, 1);
        }
      }
    }
    else {
      if (!this.config) this.config = {};
      if (!this.config[key]) this.config[key] = [];
      this.config[key].push(value);
    }
  }

  back(): void {
    this.store.dispatch(setDisplaySitesFilters({display: false}));
  }

  clear(): void {
    this.config = {};
    this.setup(this.config, this.sites);
    this.store.dispatch(setSitesFilters({sitesFilters: undefined}));
  }

  valid(): void {
    this.updateDetectionFilters(this.config);
    this.updateVolumeFilters(this.config);
    this.store.dispatch(setSitesFilters({sitesFilters: this.config}));
    this.back();
  }

}
