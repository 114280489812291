import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '../../models/site';
import { InspectionModule } from '../../enums/inspection-module';


@Pipe({name: 'module'})
export class InspectionModulePipe implements PipeTransform {
  transform(site: Site, allowed_modules: Array<InspectionModule>): boolean {
    if(site.modules) {return site.modules.some(r=> allowed_modules.includes(r));}
    return false;
  }
}
