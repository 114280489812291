<div class="container">
  <form [formGroup]="volumeForm">
    <div class="input-container" style="padding-right: 10px;">
      <span class="title">{{'moved_in_m3' | transloco}}</span>
      <input type="number"
             formControlName="threshold"
             step="0.1"
             (focusin)="onInputHandleFocus($event)"
             (focusout)="onInputLeaveFocus($event)">
    </div>

    <div class="input-container">
      <span class="title">{{'step_in_m3' | transloco}}</span>
      <input type="number"
             formControlName="step"
             step="0.1"
             (focusin)="onInputHandleFocus($event)"
             (focusout)="onInputLeaveFocus($event)">
    </div>

    <button mat-icon-button
            color="primary"
            matTooltip="{{'refresh_volumes' | transloco}}"
            (click)="loadVolumes()"
            [disabled]="!volumeForm.valid">
      <mat-icon>refresh</mat-icon>
    </button>
  </form>
</div>

