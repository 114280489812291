<div *ngIf="(measures$ | async) as measures" class="container">
  <div *ngIf="measures.length == 0" class="info">
    {{'used' | transloco}} <mat-icon>straighten</mat-icon> {{'or' | transloco}} <mat-icon>activity_zone</mat-icon> {{'placeholder_list_measure' | transloco}}
  </div>
  <measure-card
    *ngFor="let item of measures;"
    [measure]="item">
  </measure-card>
</div>


