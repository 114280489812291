<mat-card *ngIf="measure"
          style="cursor: pointer;"
          [style.border]=border
          (click)="onClickEdit(measure)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{measure.name}}</mat-card-title>
    <mat-card-subtitle *ngIf="measure.straightMeasure">{{'straight_line_distance' | transloco}}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="measure.areaVolume">{{'area_and_volume' | transloco}}</mat-card-subtitle>
  </mat-card-header>
</mat-card>



