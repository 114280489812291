import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromSite from './site.reducer';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromSite.adapter.getSelectors();

export const selectSiteState = createFeatureSelector<fromSite.State>('sites');

export const selectSiteIds = createSelector(
  selectSiteState,
  selectIds
);
export const selectSiteEntities = createSelector(
  selectSiteState,
  selectEntities
);
export const selectAllSites = createSelector(
  selectSiteState,
  selectAll
);
export const selectSiteTotal = createSelector(
  selectSiteState,
  selectTotal
);

export const selectCurrentSiteId = createSelector(
  selectSiteState,
  fromSite.getSelectedSiteId
);

export const selectCurrentSite = createSelector(
  selectSiteEntities,
  selectCurrentSiteId,
  (siteEntities, siteId) => (siteId) ? siteEntities[siteId] : null
);

export const selectSearchSiteText = createSelector(
  selectSiteState,
  fromSite.getSearchSiteText
);

export const selectSiteDensityHighlight = createSelector(
  selectSiteState,
  fromSite.getSiteDensityHighlight
);
