import { createAction, props } from '@ngrx/store';
import { CameraConfiguration } from './cesium.reducer';
import { DrawingMode } from '../../enums/drawing-mode';

export const setCameraMode = createAction('[Cesium] Set Camera Mode', props<{ cameraMode: any}>());
export const setCameraConfiguration = createAction('[Cesium] Set Camera Coniguration', props<{ cameraConfiguration: CameraConfiguration | undefined}>());
export const setMiniMapCameraConfiguration = createAction('[Cesium] Set MiniMap Camera Coniguration', props<{ cameraConfiguration: CameraConfiguration | undefined}>());
export const setMiniMapIsDragging = createAction('[Cesium] Set MiniMap Is Dragging', props<{ dragging: boolean}>());
export const setMapHandleKeyboard = createAction('[Cesium] Set Map Handle Keyboard', props<{ mapHandleKeyboard: boolean}>());
export const setCameraRotationRate = createAction('[Cesium] Set Camera Rotation Rate', props<{ rotationRate: number}>());
export const setDrawingMode = createAction('[Cesium] Set Drawing Mode', props<{ drawingMode: DrawingMode | undefined}>());
export const setHeightReference = createAction('[Cesium] Set Height Reference', props<{ heightReference: number}>());
