<div class="container">
  <div class="header">
    <button mat-icon-button (click)="back()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div>{{'all_filters' | transloco}}</div>
  </div>

  <div class="content">

    <div class="content-section" *ngIf="emergencies.length">
      <label>{{'emergency_type' | transloco}}</label>
      <mat-checkbox *ngFor="let item of emergencies"
                    color="primary"
                    [checked]="isSelected(item.value, siteFilterKeysEnum.EMERGENCIES)"
                    (change)="updateConfig(item.value, siteFilterKeysEnum.EMERGENCIES)">
        {{ item.value }}
      </mat-checkbox>
    </div>

    <div *ngIf="sectors" class="content-section">
      <label>{{'sectors' | transloco}}</label>
      <mat-checkbox *ngFor="let item of sectors"
                    color="primary"
                    [checked]="isSelected(item.id, siteFilterKeysEnum.SECTORS)"
                    (change)="updateConfig(item.id, siteFilterKeysEnum.SECTORS)">
        {{ item.name }}
      </mat-checkbox>
    </div>

    <div class="content-section">
      <label>{{'detections' | transloco}}</label>

      <filter-slider
        title="{{'emergency_hight_level' | transloco}}"
        [(data)]="detectionHighEmergencyLevelFilterSlide">
      </filter-slider>

      <filter-slider
        title="{{'total' | transloco}}"
        [(data)]="detectionTotalFilterSlide">
      </filter-slider>

      <filter-slider
        title="{{'evolution' | transloco}}"
        [(data)]="detectionEvolutionFilterSlide">
      </filter-slider>

      <filter-slider
        title="{{'density_peak' | transloco}}"
        [(data)]="detectionDensityPeakFilterSlide">
      </filter-slider>

      <!--    <mat-checkbox *ngFor="let item of detections"-->
      <!--                  [checked]="isSelected(item.value, siteFilterKeysEnum.DETECTIONS)"-->
      <!--                  (change)="updateConfig(item.value, siteFilterKeysEnum.DETECTIONS)">-->
      <!--      {{ item.label }}-->
      <!--    </mat-checkbox>-->
    </div>

    <div class="content-section">
      <label>{{'volumes' | transloco}}</label>

      <filter-slider
        title="{{'emergency_hight_level' | transloco}}"
        [(data)]="volumeHighEmergencyLevelFilterSlide">
      </filter-slider>

      <filter-slider
        title="{{'positive_volume_in_m3' | transloco}}"
        [(data)]="volumePositiveVolumeMovedFilterSlide">
      </filter-slider>

      <filter-slider
        title="{{'negative_volume_in_m3' | transloco}}"
        [(data)]="volumeNegativeVolumeMovedFilterSlide">
      </filter-slider>

      <filter-slider
        title="{{'density_peak' | transloco}}"
        [(data)]="volumeDensityPeakFilterSlide">
      </filter-slider>

    </div>
  </div>

  <div class="actions">
    <button mat-button (click)="clear()">
      {{'clear_all' | transloco}}
    </button>
    <button  mat-button (click)="valid()">
      {{'validate_filters' | transloco}}
    </button>
  </div>
</div>

