<div class="container">
  <div cesium [handleKeyboard]="mapHandleKeyboard"></div>

  <volume-color-ramp *ngIf="selectedSite
  && (selectedSite | module: [inspectionModule.PERREAD])">
  </volume-color-ramp>

  <compute-loader></compute-loader>
  <view-mode-selector *ngIf="selectedSite"></view-mode-selector>

  <detection-details></detection-details>
  <volume-details></volume-details>
  <measure-details></measure-details>
  <cut-details></cut-details>
  <marker-details></marker-details>

  <world-search *ngIf="selectedSite"></world-search>
</div>
