<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>compare_arrows</mat-icon>&nbsp;{{'organizations' | transloco}}
    </mat-card-title>
    <mat-card-subtitle>{{'select_organization' | transloco}}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-form-field>
        <mat-label>{{'organization' | transloco}}</mat-label>
        <mat-select required [formControl]="organizationFormControl">
          <mat-option *ngFor="let item of organizations"
                      [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-button (click)="dialogRef.close(false)">{{'cancel' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(organizationFormControl.value)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
