import { ColorTools } from './color.tools';
import { Color } from '../enums/color';


export class EmergencyTools {

  static backgroundColor(level: number): string {
    switch (level) {
      case 1: {
        return ColorTools.HEXToRGBA(Color.EMERGENCY_HIGH, 0.1);
      }
      case 2: {
        return ColorTools.HEXToRGBA(Color.EMERGENCY_MEDIUM_HIGH, 0.1);
      }
      case 3: {
        return ColorTools.HEXToRGBA(Color.EMERGENCY_MEDIUM_LOW, 0.1);
      }
      case 4: {
        return ColorTools.HEXToRGBA(Color.EMERGENCY_LOW, 0.1);
      }
      default: {
        return 'rgba(0,0,0,0)';
      }
    }
  }

  static border(level: number): string {
      switch (level) {
        case 1: {
          return '0.5px solid ' + Color.EMERGENCY_HIGH;
        }
        case 2: {
          return '0.5px solid ' + Color.EMERGENCY_MEDIUM_HIGH;
        }
        case 3: {
          return '0.5px solid ' + Color.EMERGENCY_MEDIUM_LOW;
        }
        case 4: {
          return '0.5px solid ' + Color.EMERGENCY_LOW;
        }
        default: {
          return '0.1px solid rgba(0, 0, 0, 0.1)';
        }
      }
    }

  static textColor(level: number): string {
    switch (level) {
      case 1: {
        return Color.EMERGENCY_HIGH;
      }
      case 2: {
        return Color.EMERGENCY_MEDIUM_HIGH;
      }
      case 3: {
        return Color.EMERGENCY_MEDIUM_LOW;
      }
      case 4: {
        return Color.EMERGENCY_LOW;
      }
      default: {
        return 'rgba(0,0,0,0)';
      }
    }
  }
}
