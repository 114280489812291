import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { PanZoomConfig, PanZoomConfigOptions } from 'ngx-panzoom';


@Component({
  selector: 'picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent implements AfterViewInit {

  @Input({required: true}) url: string | undefined;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement> | undefined;
  private options: PanZoomConfigOptions = {
    zoomLevels: 10,
  };
  panZoomConfig: PanZoomConfig = new PanZoomConfig(this.options);

  ngAfterViewInit(): void {
    this.draw();
  }

  private draw(): void {
    if(this.canvas) {
      const ctx = this.canvas.nativeElement.getContext('2d');
      if (ctx && this.url) {
        const img = new Image();
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          console.log(width, height);
          ctx.drawImage(img, 0, 0, width, height);
        };
        img.src = this.url;
      }
    }
  }
}
