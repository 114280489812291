import { autoserialize } from 'cerialize';

export class EmergencyTemplateLabel {
  @autoserialize id!: number;
  @autoserialize label!: string;
  @autoserialize level!: number;
  @autoserialize priority!: number;
}

export class EmergencyTemplate {
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize labels!: Array<EmergencyTemplateLabel>;

}
