<mat-card *ngIf="volume"
          style="cursor: pointer;"
          [style.border]=volume.border
          (click)="onClickEdit(volume)">

  <div class="card-status"
       *ngIf="volume.emergency"
       [style.background-color]=volume.backgroundColor
       [style.color]=volume.textColor>
    {{volume.emergency.label}}
  </div>

  <mat-card-header style="padding-bottom: 16px; padding-left: 42px !important; position: relative">
    <div [style.background-color]="volume.hexColor"
         style="width: 10px; position: absolute; top: 16px; bottom: 16px; left: 16px;"></div>
    <mat-card-title>{{volume.name}}</mat-card-title>
    <mat-card-subtitle>{{volume.value | number:'1.2-2'}} m3</mat-card-subtitle>
  </mat-card-header>
</mat-card>



