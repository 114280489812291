import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  selectDetectionTemplate
} from '../../../features/detection/detection.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DetectionTemplate } from '../../../models/detection-template';
import { Store } from '@ngrx/store';

@Component({
  selector: 'create-detection-dialog',
  templateUrl: './create-detection.dialog.html',
  styleUrls: ['../dialog.scss']
})
export class CreateDetectionDialog {


  message:string = '';
  label: string | undefined;
  detectionTemplate: DetectionTemplate | undefined;

  constructor(private store: Store, public dialogRef: MatDialogRef<CreateDetectionDialog>) {
    this.initHandleDetectionTemplate();
  }

  private initHandleDetectionTemplate(): void {
    this.store.select(selectDetectionTemplate)
      .pipe(takeUntilDestroyed())
      .subscribe((detectionTemplate: DetectionTemplate | undefined) => {
        this.detectionTemplate = detectionTemplate
      });
  }
}
