import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as SiteActions from './site.actions';
import {
  DetectionDensity,
  Site,
  VolumeDensity
} from '../../models/site';


export interface State extends EntityState<Site> {
  selectedSiteId: string | undefined;
  searchSiteText: string | undefined
  siteDensityHighlight: DetectionDensity | VolumeDensity | undefined;
}

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>();

export const initialState: State = adapter.getInitialState({
  selectedSiteId: undefined,
  searchSiteText: undefined,
  siteDensityHighlight: undefined
});

const siteReducer = createReducer(
  initialState,
  on(SiteActions.selectSite, (state, { siteId }) => {
    return { ...state, selectedSiteId: siteId };
  }),
  on(SiteActions.setSearchSiteText, (state, { text }) => {
    return { ...state, searchSiteText: text };
  }),
  on(SiteActions.setSiteDensityHighlight, (state, { density }) => {
    return { ...state, siteDensityHighlight: density };
  }),
  on(SiteActions.loadSites, (state) => {
    return state;
  }),
  on(SiteActions.loadSitesSuccess, (state, { sites }) => {
    return adapter.setAll(sites, state);
  }),
  on(SiteActions.loadSitesFailure, (state, { error }) => {
    return state;
  }),
  on(SiteActions.retrieveSite, (state) => {
    return state;
  }),
  on(SiteActions.retrieveSiteSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(SiteActions.retrieveSiteFailure, (state, { error }) => {
    return state;
  }),
  on(SiteActions.activateSite, (state) => {
    return state;
  }),
  on(SiteActions.activateSiteSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(SiteActions.activateSiteFailure, (state, { error }) => {
    return state;
  }),
  on(SiteActions.deactivateSite, (state) => {
    return state;
  }),
  on(SiteActions.deactivateSiteSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(SiteActions.deactivateSiteFailure, (state, { error }) => {
    return state;
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return siteReducer(state, action);
}

export const getSelectedSiteId = (state: State) => state.selectedSiteId;
export const getSearchSiteText = (state: State) => state.searchSiteText;
export const getSiteDensityHighlight = (state: State) => state.siteDensityHighlight;

