import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';


@Pipe({name: 'since'})
export class DateSincePipe implements PipeTransform {

  constructor(private translocoService: TranslocoService) {
  }

  transform(timestamp: string | number | undefined): string | null {
    if (timestamp === undefined) {
      return null;
    }
    const now = new Date();
    const date = new Date(timestamp);

    let diff = (now.getTime() - date.getTime()) / 1000;
    diff = Math.abs(Math.floor(diff));

    const days = Math.floor(diff / (24 * 60 * 60));
    let leftSec = diff - days * 24 * 60 * 60;

    const hrs = Math.floor(leftSec / (60 * 60));
    leftSec = leftSec - hrs * 60 * 60;

    const min = Math.floor(leftSec / (60));

    const datePipe = new DatePipe(this.translocoService.getActiveLang());

    if (now.getFullYear() === date.getFullYear()) {
      if (days === 0) {
        if (hrs === 0) {
            return (min === 0) ? leftSec + ' sec' : min + ' min';
        }
        else {
          return (now.getDay() !== date.getDay()) ? 'hier' : datePipe.transform(date, 'HH') +'h' +datePipe.transform(date, 'mm');
        }
      }
      else {
        return (days <= 7) ? datePipe.transform(date, 'EEE') : datePipe.transform(date, 'dd MMM');
      }
    }
    else {
      return datePipe.transform(date, 'dd MMM y');
    }
  }
}

@Pipe({name: 'format'})
export class DateFormatPipe implements PipeTransform {
  transform(timestamp: string | number | undefined): string | null {
    if (timestamp === undefined) {
      return null;
    }
    const now = new Date();
    const date = new Date(timestamp);

    let diff = (now.getTime() - date.getTime()) / 1000;
    diff = Math.abs(Math.floor(diff));

    const days = Math.floor(diff / (24 * 60 * 60));
    let leftSec = diff - days * 24 * 60 * 60;

    const hrs = Math.floor(leftSec / (60 * 60));
    leftSec = leftSec - hrs * 60 * 60;

    const min = Math.floor(leftSec / (60));

    const sec = leftSec - (min * 60);
    if (hrs === 0) {
      if (min === 0) {
        return sec + ' sec';
      }else {
        return min + ' min ' + sec  + ' sec';
      }
    }
    else {
      return  hrs + ' hour ' + min + ' min ';
    }
  }
}


@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  transform(diff: number | undefined): string | null {
    if (diff === undefined) {
      return null;
    }

    diff = Math.abs(Math.floor(diff));

    const days = Math.floor(diff / (24 * 60 * 60));
    let leftSec = diff - days * 24 * 60 * 60;

    const hrs = Math.floor(leftSec / (60 * 60));
    leftSec = leftSec - hrs * 60 * 60;

    const min = Math.floor(leftSec / (60));

    const sec = leftSec - (min * 60);
    if (hrs === 0) {
      if (min === 0) {
        return sec + ' sec';
      }else {
        return min + ' min ' + sec  + ' sec';
      }
    }
    else {
      if (days > 0) {
        return  days + ' day ' + hrs + ' hour ';
      }
      return  hrs + ' hour ' + min + ' min ';
    }
  }
}

@Pipe({name: 'itemName'})
export class ItemNamePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {
  }

  transform(label: string, id: string): string {
    return this.translocoService.translate(label) + ' ' + id.slice(0,4).toUpperCase();
  }
}


@Pipe({name: 'wgs84'})
export class ECEFToWGS84Pipe implements PipeTransform {

  transform(ecef: Array<number>): string {
    const cartographic = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromArray(ecef));
    const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    const latitude = Cesium.Math.toDegrees(cartographic.latitude);
    return longitude.toFixed(10) + ", " + latitude.toFixed(10);
  }
}

