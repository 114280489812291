import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as SectorActions from './sector.actions';
import { Sector } from '../../models/sector';


export interface State extends EntityState<Sector> {}

export const adapter: EntityAdapter<Sector> = createEntityAdapter<Sector>();

export const initialState: State = adapter.getInitialState({});

const sectorReducer = createReducer(
  initialState,
  on(SectorActions.loadSectors, (state) => {
    return state;
  }),
  on(SectorActions.loadSectorsSuccess, (state, { sectors }) => {
    return adapter.setAll(sectors, state);
  }),
  on(SectorActions.loadSectorsFailure, (state, { error }) => {
    return state;
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return sectorReducer(state, action);
}



